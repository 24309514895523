import React from 'react';
import { Grow, IconButton, Paper, Typography } from '@material-ui/core';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './style';

export default function UserNotice({ enabled, state, message, onClose }) {
    const success = state === 'success';
    const classes = useStyles();

    const renderSuccessHeader = () => (
        <div className={classes.user_notice_header}>
            <CheckSharpIcon className={classes.user_notice_check_icon_success} />
            <Typography className={classes.user_notice_typography_success}>
                <b>Successful</b>
            </Typography>
            <div className={classes.filler} />
            <IconButton aria-label="close" className={classes.user_notice_close} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </div>
    );

    return (
        <Grow in={enabled}>
            <Paper className={success ? classes.user_notice_paper_success : classes.user_notice_paper} elevation={2}>
                {success && renderSuccessHeader()}
                <Typography className={classes.user_notice_message}>
                    {message}
                </Typography>
            </Paper>
        </Grow>
    );
}
