import {
    filterSubValidations,
    openSubValidationsDeletePopUp,
    openSubValidationsEditPopup,
    sortSubValidations,
} from './subValidationsSlice';
import fieldTypes from '../../../components/Table/fieldTypes';
import store from '../../../app/store';
import EditIcon from '../edit.png';
import DeleteIcon from '../delete.png';

export default function columns(writeAccess) {
    return (
        [
            {
                field: 'code',
                headerName: 'Code',
                width: 100,
                sort: sortSubValidations,
            },
            {
                field: 'text',
                headerName: 'Text',
                filter: filterSubValidations,
                sort: sortSubValidations,
            },
            {
                field: 'mainVersion',
                headerName: 'Main version',
                width: 100,
                sort: sortSubValidations,
            },
            {
                field: 'year',
                headerName: 'Year',
                width: 100,
                sort: sortSubValidations,
            },
            {
                field: 'vendor',
                headerName: 'Vendor',
                width: 100,
                sort: sortSubValidations,
            },
            {
                field: 'edit',
                headerName: 'Edit',
                type: fieldTypes.Icon,
                width: 80,
                noGrow: true,
                src: EditIcon,
                size: 22,
                renderButton: {
                    disabled: !writeAccess,
                    onClick: () => store.dispatch(openSubValidationsEditPopup()),
                    avatar: EditIcon
                }
            },
            {
                field: 'delete',
                headerName: 'Delete',
                type: fieldTypes.Icon,
                width: 80,
                noGrow: true,
                src: DeleteIcon,
                size: 22,
                renderButton: {
                    disabled: !writeAccess,
                    onClick: () => store.dispatch(openSubValidationsDeletePopUp()),
                    avatar: DeleteIcon
                }
            },
        ]
    );
}
