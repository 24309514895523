import React, { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import PopUp from '../PopUp/PopUp';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import callApi from '../../app/api';
import ErrorNotice from '../ErrorNotice/ErrorNotice';
import { ReactComponent as UploadIcon } from '../../resources/icons/Upload.svg';
import useStyles from './style';

function AddNewProjectDialog({ open, setOpen, onClose, projectNames }) {
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState();
    const [sentToProcess, setSentToProcess] = useState(false);
    const [processError, setProcessError] = useState();
    const [openLoadDialog, setOpenLoadDialog] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [existingProject, setExistingProject] = useState(false);

    useEffect(() => {
        setOpenLoadDialog(open);
    }, [open, openLoadDialog]);

    function handleCloseAddProjectDialog() {
        setProcessError();
        if (!sentToProcess) {
            setOpenLoadDialog(false);
        }
        onClose();
    }

    async function handleAddProjectSubmit(e) {
        setSentToProcess(true);
        e.preventDefault();
        const data = new FormData();
        data.append('name', e.target[0].value);
        data.append('description', e.target[2].value);
        data.append('workspace_id', window.sessionStorage.workspaceID);
        data.append('file', selectedFile);
        const response = await callApi('POST', 'add_project', data, true).then((resp) => {
            setSentToProcess(false);
            onClose();
        }).catch(e => {
            if (e.response.status === 404) {
                handleCloseAddProjectDialog();
            }
            setProcessError(`${e.response.status} ${e.response.statusText}`);
            setSentToProcess(false);
        });
    }

    function onProjectNameChange(event) {
        setProjectName(event.target.value);
        setExistingProject(projectNames.includes(event.target.value));
    }

    function onUploadChangeHandler(event) {
        setSelectedFile(event.target.files[0]);
    }

    function renderHelperText() {
        if (selectedFile) {
            return `Current file: ${selectedFile.name}`;
        }
        return 'Upload the AAT related .xlsx (Excel) or .json file. Max 1MB.';
    }

    function renderSaveProjectDialogContent() {
        if (sentToProcess || processError) {
            if (processError) {
                return (
                    <div className={classes.add_project_dialog_content_processing}>
                        <ErrorNotice label={processError} />
                    </div>
                );
            }
            return (
                <div className={classes.add_project_dialog_content_processing}>
                    <Typography variant="body1">
                        {' '}
                        Processing
                        {' '}
                        {selectedFile.name}
                    </Typography>
                    <Typography variant="body2">Please wait and don&apos;t close the browser!</Typography>
                    <LoadingIcon />
                </div>
            );
        }

        return (
            <Paper component="form" onSubmit={(e) => handleAddProjectSubmit(e)} className={classes.add_project_dialog_content}>
                <div className={classes.add_project_dialog_project_name}>
                    <Typography variant="body1" className={classes.add_project_dialog_input_header}>
                        <b>Project*</b>
                    </Typography>
                    <TextField label="Project Name" onChange={onProjectNameChange} fullWidth variant="outlined" inputProps={{ maxLength: 40 }} helperText="max 40 characters" />
                    {existingProject && <Alert severity="warning">A project already exists with the same name!</Alert>}
                </div>
                <div className={classes.add_project_dialog_description}>
                    <Typography variant="body1" className={classes.add_project_dialog_input_header}>
                        <b>Description</b>
                    </Typography>
                    <TextField label="Description" fullWidth multiline minRows={6} variant="outlined" inputProps={{ maxLength: 255 }} helperText="max 255 characters" />
                </div>
                <div className={classes.add_project_dialog_upload_button}>
                    <Typography variant="body1" className={classes.add_project_dialog_input_header}>
                        <b>Upload File*</b>
                    </Typography>
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<UploadIcon />}
                      fullWidth
>
                        <Typography variant="body1" style={{ textTransform: 'none' }}>Upload File</Typography>
                        <input accept=".xlsx,.json" hidden type="file" name="file" onChange={onUploadChangeHandler} />
                    </Button>
                    <FormHelperText>{renderHelperText()}</FormHelperText>
                </div>
                <div className={classes.add_project_dialog_save_button}>
                    <Button type="submit" variant="contained" color="primary" disabled={existingProject || !projectName || !selectedFile}>
                        Save
                    </Button>
                </div>
            </Paper>
        );
    }

    return (
        <PopUp title="Add Project" onClose={handleCloseAddProjectDialog} open={openLoadDialog}>
            {renderSaveProjectDialogContent()}
        </PopUp>
    );
}

export default AddNewProjectDialog;
