export const msalConfig = {
    auth: {
        tenant: process.env.REACT_APP_TENANT_ID,
        clientId: process.env.REACT_APP_CLIENT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
        authority: process.env.REACT_APP_AUTHORITY,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};
