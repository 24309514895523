import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { useSelector } from 'react-redux';
import useStyles from './style';
import HomeIcon from '../../resources/icons/Home.svg';
import MenuIcon from '../../resources/icons/Menu.svg';
import ProjectsIcon from '../../resources/icons/Projects.svg';
import ValidationsIcon from '../../resources/icons/Validations.svg';
import CloseIcon from '../../resources/icons/Close.svg';

function SideBar() {
    const classes = useStyles();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const workspaceId = useSelector(state => state.projects.workspaceID);

    const isProjectActive = () => (location.pathname === '/' || location.pathname === `/${workspaceId}` || location.pathname.startsWith('/project'));

    function renderToggleMenuIcon() {
        return (
            <div className={classes.toolbar}>
                <ToggleButton
                  value="toggle"
                  selected={false}
                  onClick={() => setOpen((open) => !open)}>
                    {open ? <img src={CloseIcon} alt="Close" /> : <img src={MenuIcon} alt="Menu" />}
                </ToggleButton>
            </div>
        );
    }

    function renderStage() {
        if (process.env.REACT_APP_ENV === 'PROD') {
            return;
        }
        return (
            <div className={classes.app_environment}>
                <Typography variant="caption">{process.env.REACT_APP_ENV}</Typography>
            </div>
        );
    }

    function render() {
        return (
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, { [classes.drawerOpen]: open, [classes.drawerClose]: !open, })}
              classes={{ paper: clsx(classes.drawer_paper, { [classes.drawerOpen]: open, [classes.drawerClose]: !open, }), }}
                >
                {renderToggleMenuIcon()}
                <List>
                    <Link to={{ pathname: `${process.env.REACT_APP_REDIRECT_URL}${workspaceId}` }} target="_blank" className={classes.menuItem}>
                        <Tooltip title="IEMP portal" placement="right">
                            <ListItem button key="IEMP Home">
                                <ListItemIcon><img src={HomeIcon} alt="Home" /></ListItemIcon>
                                <ListItemText className={classes.menuItem} primary="IEMP Home" />
                            </ListItem>
                        </Tooltip>
                    </Link>
                    <NavLink to="/" isActive={isProjectActive} activeClassName={classes.selected_menuItem} className={classes.menuItem}>
                        <Tooltip title="Projects" placement="right">
                            <ListItem button key="Projects">
                                <ListItemIcon><img src={ProjectsIcon} alt="Projects" /></ListItemIcon>
                                <ListItemText primary="Projects" />
                            </ListItem>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/validations" activeClassName={classes.selected_menuItem} className={classes.menuItem}>
                        <Tooltip title="Validations" placement="right">
                            <ListItem button key="Validations">
                                <ListItemIcon><img src={ValidationsIcon} alt="Validations" /></ListItemIcon>
                                <ListItemText primary="Validations" />
                            </ListItem>
                        </Tooltip>
                    </NavLink>
                </List>
                {renderStage()}

            </Drawer>
        );
    }
    return render();
}

export default SideBar;
