import fieldTypes from '../../../components/Table/fieldTypes';
import EditIcon from '../edit.png';
import DeleteIcon from '../delete.png';
import store from '../../../app/store';
import {
    openMainValidationsDeletePopUp,
    openMainValidationsEditPopup,
} from './mainValidationsSlice';

export default function columns(writeAccess) {
    return ([
        {
            field: 'category',
            headerName: 'Category',
            width: 160,
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 160,
        },
        {
            field: 'text',
            headerName: 'Text',
            flex: 1,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            type: fieldTypes.Icon,
            width: 80,
            noGrow: true,
            src: EditIcon,
            size: 22,
            renderButton: {
                disabled: !writeAccess,
                onClick: () => store.dispatch(openMainValidationsEditPopup()),
                avatar: EditIcon,
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            type: fieldTypes.Icon,
            width: 80,
            noGrow: true,
            src: DeleteIcon,
            size: 22,
            renderButton: {
                disabled: !writeAccess,
                onClick: () => store.dispatch(openMainValidationsDeletePopUp()),
                avatar: DeleteIcon
            }
        },
    ]);
}
