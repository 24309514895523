import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    infoLabel_container: {
        minWidth: '407px',
        width: 'fit-content',
        margin: theme.spacing(1, 8, 1, 0),
        padding: theme.spacing(1, 0),
        ...theme.flex.row,
        flexWrap: 'wrap',
        '&:hover': {
            background: grey[50],
        }
    },

    infoLabel_title: {
        minWidth: '188px',
        ...theme.flex.row,
        justifyContent: 'space-between'
    },

    infoLabel_value: {
        paddingLeft: theme.spacing(1.75)
    },
});

export default useStyles;
