import axios from 'axios';
import { API_URL } from './settings';
import { msalInstance } from '..';

const callApi = async (method, path, params, hasFileContent = false) => {
    const url = new URL(path, API_URL);
    if (method === 'GET') {
        Object.keys(params || {}).forEach((key) => url.searchParams.append(key, params[key]));
    }

    const authToken = await getAuthToken();

    let apiResponse;
    if (!hasFileContent) {
        apiResponse = await fetch(url.toString(), {
            method,
            body: method === 'GET' ? null : params,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authToken
            },
        }).catch(err => { throw err; });
    } else {
        let config = { headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
        } };
        apiResponse = await axios.post(url.toString(), params, config).catch(err => { throw err; });
    }

    if (apiResponse.ok) {
        return apiResponse.json();
    }
    return apiResponse;
};

const getAuthToken = async () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        return null;
    }
    const request = {
        scopes: [`${process.env.REACT_APP_SCOPE}/user_impersonation`],
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
};

export default callApi;
