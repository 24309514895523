import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './style';

function Icon({ data, column }) {
    const classes = useStyles();
    const onClick = () => {
        if (column.onClick) {
            column.onClick(data);
        }
    };

    return (
        <img
          src={column.src}
          alt={column.title}
          title={column.title}
          width={column.size}
          height={column.size}
          className={classes.icon}
          onClick={onClick}
        />
    );
}

Icon.propTypes = {
    data: PropTypes.object,
    column: PropTypes.object,
};

export default Icon;
