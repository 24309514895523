import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useStyles from './style';
import UserProfile from '../UserProfile/UserProfile';
import Logo from '../Logo/Logo';
import WorkspaceLogo from '../WorkspaceLogo/WorkspaceLogo';

function Header() {
    const classes = useStyles();
    const location = useLocation();
    const workspaceId = useSelector(state => state.projects.workspaceID);

    const isHomePage = () => (location.pathname === '/' || location.pathname === `/${workspaceId}`);

    const renderLogo = () => {
        if (isHomePage()) {
            return (
                <div className={classes.logo_container}>
                    <Logo />
                </div>
            );
        }

        return (
            <Link to={{ pathname: `/${workspaceId}` }} className={classes.logo_container}>
                <Logo />
            </Link>
        );
    };

    function render() {
        return (
            <>
                <header className={classes.header_container}>
                    {renderLogo()}
                    <div id="headerTitle" className={classes.header_title}>
                        <Typography variant="h6"><b>Asset Assessment Tool</b></Typography>
                    </div>
                    <div className={classes.header_filler} />
                    <WorkspaceLogo />
                    <UserProfile />
                </header>
                <div className={classes.header_bottom} />
            </>
        );
    }
    return render();
}

export default Header;
