import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useStyles from './style';
import DynamicImport from '../../components/DynamicImport/DynamicImport';
import { fetchProjects, setProject } from './projectsSlice';
import PageTitle from '../../components/PageTitle/PageTitle';

function Project(props) {
    const { match, projects, setProject } = props;
    const classes = useStyles();

    useEffect(() => {
        if (!projects) {
            props.fetchProjects();
        }
    }, [props, projects]);

    useEffect(() => {
        const { projectId } = match.params;
        if (projects) {
            const project = projects.find((project) => String(project.id) === projectId);
            setProject(project);
        }
    }, [match.params.projectId, projects, setProject]);

    function getProject(pahType) {
        return <DynamicImport load={() => import('./ProjectSchema')} />;
    }

    function render() {
        const { projects, project } = props;
        if (!projects || !project) return null;
        return (
            <>
                <PageTitle label={project.name} accordion description={project.description} />
                {getProject(project.pahType)}
            </>
        );
    }
    return render();
}

Project.propTypes = {
    match: PropTypes.object,
    projects: PropTypes.array,
    fetchProjects: PropTypes.func,
    setProject: PropTypes.func,
};

const mapStateToProps = ({ projects }) => ({
    projects: projects.projects,
    project: projects.project,
});

const mapDispatchToProps = {
    fetchProjects,
    setProject,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Project));
