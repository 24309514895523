import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    logo_symbol: {
        minWidth: '30.55px',
        minHeight: '33.53px',
        width: '3vmin',
        height: '3vmin'
    },
    logo_title: {
        minWidth: '72px',
        minHeight: '34.12px',
        width: '6vmin',
        height: 'auto',
        margin: theme.spacing(0, 2, 0, 2)
    }
});

export default useStyles;
