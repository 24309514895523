import { createTheme } from '@material-ui/core/styles';
import flex from './flex';
import accenture from './colors';
import Graphik from '../resources/fonts/Graphik-Regular.ttf';

const graphic = {
    fontFamily: 'Graphik',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('Graphik'),
    local('Graphik-Regular'),
    url(${Graphik}) format('ttf')
  `,
    unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const theme = createTheme({
    typography: {
        fontFamily: 'Graphik, Arial',
    },
    flex,
    sidebar: '86px',
    spacing: (factor) => `${0.25 * factor}rem`,
    palette: {
        primary: {
            light: '#BF1DC6',
            main: '#7A2581',
            dark: '#390289',
            blue: '#0041f0',
            grey: '#F8F8F7',
            shadow: '#9EA4BA33'
        },
        secondary: {
            main: '#56008d', // nope
            light: '#6500a6', // van
            dark: accenture.core.purple3, // van
        },
        // van
        accenture, // van
    },
});

export default theme;
