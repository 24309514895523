const accenture = {
    core: {
        purple1: '#a100ff',
        purple2: '#7500c0',
        purple3: '#460073',
    },
    accent: {
        purple1: '#b455aa',
        purple2: '#a055f5',
        purple3: '#be82ff',
        purple4: '#dcafff',
        purple5: '#e6dcff',
    },
    blue: '#0041f0',
    lightBlue: '#00ffff',
    green: '#64ff50',
    blueGreen: '#05f0a5',
    red: '#ff3246',
    pink: '#ff50a0',
    orange: '#ff7800',
    yellow: '#ffeb32',
};

export default accenture;
