import React from 'react';
import { FormControl, MenuItem, OutlinedInput, Select, Tooltip, Typography } from '@material-ui/core';

export default function Dropdown({ values, currentValue, onChange }) {
    return (
        <FormControl>
            <Select
              MenuProps={{ getContentAnchorEl: null }}
              value={currentValue}
              onChange={onChange}
              input={<OutlinedInput />}>
                {values.map(x => (
                    <MenuItem key={x.value} value={x.value}>
                        <Tooltip title={x.title} placement="top">
                            <Typography variant="inherit" noWrap>
                                {x.title}
                            </Typography>
                        </Tooltip>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
