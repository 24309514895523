import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    header_container: {
        background: 'white',
        color: theme.palette.primary.dark,
        minHeight: '6.25vh',
        height: '6.25vh',
        ...theme.flex.row,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    header_bottom: {
        minHeight: '0.8vh',
        height: '0.8vh',
        background: 'linear-gradient(to right, #3B087A 0%, #B315A1 100%)',
    },

    logo_container: {
        paddingLeft: theme.spacing(4),
        height: '5vh',
        ...theme.flex.row,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    header_title: {
        height: '4vh',
        borderLeft: '1px solid #cdcdcd',
        marginLeft: theme.spacing(6),
        paddingLeft: theme.spacing(2),
        ...theme.flex.row,
        alignItems: 'center',
        userSelect: 'none',
        color: '#2E2E2E',
    },
    header_filler: {
        flexGrow: 1
    },
    header_profile: {

    }
});

export default useStyles;
