import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    title_container: {
        ...theme.flex.row,
        maxHeight: '6.25vh',
        background: 'white',
        '& .MuiButton-outlined': {
            color: 'theme.palette.primary.main !important',
            fontWeight: 900,
            borderColor: theme.palette.primary.main,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
    main_container: {
        padding: theme.spacing(5),
        ...theme.flex.column,
        background: theme.palette.primary.grey,
    },
    projects_container: {
        ...theme.flex.row,
        flexWrap: 'wrap',
    },
    filler: {
        flexGrow: 1,
    },
    add_project_box: {
        ...theme.flex.column,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: theme.shadows[1],
        shadowColor: theme.palette.primary.shadow,
        textDecoration: 'none',
        padding: theme.spacing(4),
        margin: theme.spacing(4, 3),
        width: '297px',
        height: '193.52px',
        background: 'white',
        border: '2px solid white',
        borderRadius: 6,
        '&:active': {
            borderColor: theme.palette.primary.blue
        },
        '&:focus': {
            borderColor: theme.palette.primary.blue
        },
        cursor: 'pointer'
    },
    add_project_box_label: {
        color: theme.palette.primary.main,
    },
    new_project_prompt: {
        width: '100%',
        ...theme.flex.row,
        justifyContent: 'center',
        marginTop: theme.spacing(4)
    },

    new_project_button: {
        padding: 0,
    },

    new_project_button_label: {
        color: theme.palette.primary.main
    },

    project_container: {
        ...theme.flex.column,
        justifyContent: 'space-between',
        boxShadow: theme.shadows[1],
        shadowColor: theme.palette.primary.shadow,
        textDecoration: 'none',
        color: 'black',
        padding: theme.spacing(4),
        margin: theme.spacing(4, 3),
        width: '297px',
        height: '193.52px',
        background: 'white',
        border: '2px solid white',
        borderRadius: 6,
        '&:active': {
            borderColor: theme.palette.primary.blue
        },
        '&:focus': {
            borderColor: theme.palette.primary.blue
        },
    },
    project_description: {
        marginTop: theme.spacing(2),
        display: 'box',
        lineClamp: 3,
        boxOrient: 'vertical',
        overflow: 'hidden',
    },
    project_title: {
        display: 'box',
        lineClamp: 3,
        boxOrient: 'vertical',
        overflow: 'hidden',
    },
    project_arrow: {
        width: '100%',
        ...theme.flex.row,
        justifyContent: 'flex-end',
    },
});

export default useStyles;
