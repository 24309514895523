import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    icon: {
        margin: '0 auto',
        cursor: 'pointer',
    },
});

export default useStyles;
