import { combineReducers } from '@reduxjs/toolkit';
import projectsSlice from '../features/Projects/projectsSlice';

function createRootReducer(asyncReducers) {
    return combineReducers({
        projects: projectsSlice,
        ...asyncReducers,
    });
}

export default createRootReducer;
