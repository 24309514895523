import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    container: {
        position: 'absolute',
        left: '50%',
        top: '50%',
    },
});

export default useStyles;
