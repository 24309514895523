import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './style';
import callApi from '../../app/api';

function WorkspaceLogo() {
    const classes = useStyles();
    const workspaceId = useSelector(state => state.projects.workspaceID);
    const [workspaceInfo, setWorkspaceInfo] = useState({});

    useEffect(async () => {
        setWorkspaceInfo(await getWorkspaceInfo());
    }, [workspaceId]);

    const getWorkspaceInfo = async () => {
        try {
            const response = await callApi('GET', `workspace_logo/${workspaceId}`);
            return response;
        } catch {
            return {};
        }
    };

    function render() {
        return (
            <>
                <div>
                    <img className={classes.workspace_logo} src={workspaceInfo.workspaceLogo} alt="" />
                </div>
                <div>
                    <span className={classes.client_name}>{workspaceInfo.clientName}</span>
                </div>
            </>
        );
    }

    return render();
}

export default WorkspaceLogo;
