import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    workspace_logo: {
        width: '50px',
        maxHeight: '50px',
        marginRight: '10px'
    },
    client_name: {
        whiteSpace: 'nowrap',
        maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        fontFamily: 'Graphik',
        fontWeight: 'bolder'
    }
});

export default useStyles;
