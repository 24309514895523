import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    pagetitle_container: {
        minHeight: '50px',
        height: '6.25vh',
        background: 'white',
        boxShadow: theme.shadows[0],
        ...theme.flex.row,
        alignItems: 'center',
    },
    pagetitle_label: {
        color: 'black',
        marginLeft: theme.spacing(9),
    },
    pagetitle_description: {
        color: 'black',
        marginLeft: theme.spacing(9),
        paddingBottom: theme.spacing(2)
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    expanded: {
        margin: 0
    },
    pagetitle_accordion_container: {
        '& .MuiCollapse-entered': {
            margin: 0
        },
        '& .MuiAccordion-root': {
            boxShadow: theme.shadows[0],
            borderRadius: 0
        }
    },
    pagetitle_accordion: {
        '& .MuiAccordionSummary-root': {
            ...theme.flex.row,
            width: '99%'
        }
    },
    pagetitle_accordion_expand_icon: {
        justifySelf: 'flex-end'
    }
});

export default useStyles;
