import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import MainTheme from '../theme/MainTheme';
import useStyles from './style';
import Routes from '../components/Routes/Routes';
import Footer from '../components/Footer/Footer';
import SideBar from '../components/Sidebar/SideBar';
import Header from '../components/Header/Header';

function App() {
    const classes = useStyles();

    function ErrorComponent({ error }) {
        return (
            <p>
                An Error Occurred:
                {' '}
                {error}
            </p>
        );
    }

    function LoadingComponent() {
        return <p>Authentication in progress...</p>;
    }

    function render() {
        return (
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              errorComponent={ErrorComponent}
              loadingComponent={LoadingComponent}
                >
                <ThemeProvider theme={MainTheme}>
                    <div className={classes.main_wrapper}>
                        <SideBar />
                        <div className={classes.app_container}>
                            <Header />
                            <Routes />
                        </div>
                    </div>
                    <Footer />
                </ThemeProvider>
            </MsalAuthenticationTemplate>
        );
    }
    return render();
}

export default App;
