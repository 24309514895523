import React from 'react';
import Typography from '@material-ui/core/Typography';
import Zoom from 'react-awesome-reveal';
import useStyles from './style';
import ErrorIcon from '../../resources/icons/Error.svg';

function ErrorNotice({ label }) {
    const classes = useStyles();
    return (
        <div className={classes.en_container}>
            <Zoom>
                <img src={ErrorIcon} alt="Error" />
            </Zoom>
            <Typography variant="h4" color="error" className={classes.en_label}><b>{label || 'An error has occurred'}</b></Typography>
        </div>
    );
}

export default ErrorNotice;
