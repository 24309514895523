import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './style';

function PageTitle({ label, accordion, description }) {
    const classes = useStyles();
    function render() {
        if (accordion) {
            return (
                <div className={classes.pagetitle_accordion_container}>
                    <Accordion className={classes.pagetitle_accordion}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon className={classes.pagetitle_accordion_expand_icon} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                      >
                            <Typography variant="h6" className={classes.pagetitle_label}><b>{label}</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" className={classes.pagetitle_description}>
                                {description}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            );
        }
        return (
            <div className={classes.pagetitle_container}>
                <Typography variant="h6" className={classes.pagetitle_label}><b>{label}</b></Typography>
            </div>
        );
    }
    return render();
}

PageTitle.propTypes = {
    label: PropTypes.string,
};

export default PageTitle;
