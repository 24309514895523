import { makeStyles } from '@material-ui/styles';
import theme from '../theme/MainTheme';

const useStyles = makeStyles({
    main_wrapper: {
        ...theme.flex.row,
        height: '96vh',
        maxWidth: '100vw',
        overflowY: 'scroll'
    },
    app_container: {
        marginLeft: theme.sidebar,
        backgroundColor: theme.palette.primary.grey,
        maxHeight: '96vh',
        width: '100%',
        ...theme.flex.column,
    },

});

export default useStyles;
