import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';
import { fetchSubValidations, filterSubValidations, openSubValidationsEditPopup, resetFilters, selectSubValidationsPage, setSubValidationData, setSubValidationLoading } from './subValidationsSlice';
import Table from '../../../components/Table/Table';
import columns from './columns';
import EditSubValidations from './EditSubValidations';
import DeleteSubValidation from './DeleteSubValidation';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import useStyles from './style';

function SubValidations() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { parent, paging, list, subValidationData, loading, error, editPopupVisible, deletePopupVisible, writeAccess } = useSelector(
        (state) => state.validations.subValidations
    );

    useEffect(() => {
        if (parent) {
            dispatch(resetFilters());
            dispatch(fetchSubValidations());
        }
    }, [dispatch, parent]);

    const noDataOverlay = () => <Alert severity="warning">No Sub Validations!</Alert>;

    const openEditPopUp = () => {
        dispatch(setSubValidationData(null));
        dispatch(openSubValidationsEditPopup());
    };

    const onRowClick = (x) => {
        if (!subValidationData?.id || subValidationData?.id !== x.row.id) {
            let validationData = {};
            validationData.id = x.row.id;
            validationData.code = x.row.code;
            validationData.text = x.row.text;
            validationData.mainVersion = x.row.mainVersion;
            validationData.year = x.row.year;
            validationData.vendor = x.row.vendor;
            dispatch(setSubValidationData(validationData));
        }
    };

    const onPageChange = (x) => {
        dispatch(setSubValidationLoading(true));
        dispatch(selectSubValidationsPage(x + 1));
        dispatch(fetchSubValidations());
    };

    const onSearchSubmit = (e) => {
        e.preventDefault();
        const filter = { key: 'code', value: e.target[0].value };
        dispatch(filterSubValidations(filter));
    };

    const renderLoading = () => <LoadingIcon className={classes.loading_icon} />;

    const renderError = () => <Alert severity="error">There was an error while fetching data from the server.</Alert>;

    const renderContent = () => {
        if (list && parent) {
            return (
                <>
                    <Button variant="contained" color="primary" disabled={!writeAccess} onClick={openEditPopUp}>Add new</Button>
                    <Table data={list} noDataOverlay={noDataOverlay} columns={columns(writeAccess)} onRowClick={onRowClick} />
                </>
            );
        }
    };

    if (loading) {
        return renderLoading();
    }

    if (error) {
        return renderError();
    }

    return (
        <>
            {renderContent()}
            {editPopupVisible && <EditSubValidations />}
            {deletePopupVisible && <DeleteSubValidation />}
        </>
    );
}

SubValidations.propTypes = {};

export default SubValidations;
