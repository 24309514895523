import { configureStore } from '@reduxjs/toolkit';
import createRootReducer from './rootReducer';

const store = configureStore(
    {
        reducer: createRootReducer({}),
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
        }),
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ / window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
);

store.asyncReducers = {};

store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createRootReducer(store.asyncReducers));
};

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const createRootReducer = require('./rootReducer').default;
        store.replaceReducer(createRootReducer(store.asyncReducers));
    });
}

export default store;
