import { createSlice } from '@reduxjs/toolkit';
import callApi from '../../app/api';

const initialState = {
    projects: null,
    loading: true,
    error: null,
    project: null,
    writeAccess: false,
    workspaceID: window.sessionStorage.workspaceID || '',
};

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        fetchProjectsLoading: (state) => {
            state.projects = null;
            state.loading = true;
            state.error = null;
        },
        fetchProjectsSuccess: (state, action) => {
            state.projects = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchProjectsFailed: (state, action) => {
            state.projects = null;
            state.loading = false;
            state.error = action.payload;
        },
        setProject: (state, action) => {
            state.project = action.payload;
        },
        setWorkspaceID: (state, action) => {
            window.sessionStorage.workspaceID = action.payload;
            state.workspaceID = action.payload;
        },
        setWriteAccess: (state, action) => {
            state.writeAccess = action.payload;
        },
    },
});

export const { fetchProjectsLoading, fetchProjectsSuccess, fetchProjectsFailed, setProject, setWorkspaceID, setWriteAccess } = projectsSlice.actions;

export const fetchProjects = () => async (dispatch, state) => {
    const pathWorkspaceID = window.location.pathname.substring(1);
    const pathHasWorkspaceID = !(pathWorkspaceID === '');
    const paths = ['project', 'projects', 'validations', 'compatibilitymatrix'];
    const pathWorkspaceIDNotAValidRoute = !paths.includes(pathWorkspaceID.split('/')[0]);
    if (pathHasWorkspaceID && state().projects.workspaceID !== pathWorkspaceID && pathWorkspaceIDNotAValidRoute) {
        dispatch(fetchProjectsLoading());
        dispatch(setWorkspaceID(pathWorkspaceID));
    } else if (!state().projects.workspaceID) {
        if (pathHasWorkspaceID) {
            dispatch(fetchProjectsLoading());
            dispatch(setWorkspaceID(pathWorkspaceID));
        } else {
            dispatch(fetchProjectsFailed('Invalid workspace'));
            return;
        }
    }

    try {
        dispatch(fetchProjectsLoading());
        await callApi('GET', 'projects', { workspace: state().projects.workspaceID }).then((response) => {
            if (Array.isArray(response)) {
                dispatch(fetchProjectsSuccess(response));
            } else {
                throw response;
            }
        });
        await callApi('GET', 'get_write_access', { workspace: state().projects.workspaceID }).then((response) => {
            dispatch(setWriteAccess(response.access));
        });
    } catch (err) {
        if (err.statusText) {
            let message = `${err.statusText[0]}${err.statusText.substring(1).toLowerCase()}`;
            dispatch(fetchProjectsFailed(message));
        } else {
            dispatch(fetchProjectsFailed(err.toString()));
        }
    }
};

export default projectsSlice.reducer;
