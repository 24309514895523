import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './style';

function Text({ data, column }) {
    const classes = useStyles();
    return <div className={classes.container}>{data[column.field]}</div>;
}

Text.propTypes = {
    data: PropTypes.object,
    column: PropTypes.object,
};

export default Text;
