import React from 'react';
import useStyles from './style';

function Logo() {
    const classes = useStyles();

    function render() {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" className={classes.logo_symbol} viewBox="0 0 27.337 30">
                    <path id="Acc_GT_Solid_P2_RGB" d="M0,30,27.337,18.917V11.083L0,0V7.833L17.677,15,0,22.167Z" fill="#a100ff" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" className={classes.logo_title} viewBox="0 0 104.146 49.35">
                    <g id="Group_100480" data-name="Group 100480" transform="translate(-4146.473 24)">
                        <g id="Group_100932" data-name="Group 100932" transform="translate(4146.473 13.896)">
                            <path id="Path_200301" data-name="Path 200301" d="M-690.08,400.042h-4.03v10.594h3.023v-3.571h1.141a4.7,4.7,0,0,0,3.052-.873,3.1,3.1,0,0,0,1.066-2.548v-.06a3.326,3.326,0,0,0-1.036-2.615A4.729,4.729,0,0,0-690.08,400.042Zm1.363,3.615a1.275,1.275,0,0,1-.371,1,1.631,1.631,0,0,1-1.125.334h-.874v-2.667h.889a1.772,1.772,0,0,1,1.1.3,1.161,1.161,0,0,1,.385.979Z" transform="translate(694.11 -399.361)" />
                            <rect id="Rectangle_18998" data-name="Rectangle 18998" width="2.637" height="11.157" transform="translate(9.319 0.118)" />
                            <path id="Path_200302" data-name="Path 200302" d="M-673.546,403.587a3.064,3.064,0,0,0-1.1-.527,5.686,5.686,0,0,0-1.444-.169,5.867,5.867,0,0,0-1.46.169,3.38,3.38,0,0,0-1.133.511,2.68,2.68,0,0,0-.778.882,2.891,2.891,0,0,0-.333,1.222h2.489a1.2,1.2,0,0,1,.274-.645.967.967,0,0,1,.778-.274q.637,0,.83.334a1.793,1.793,0,0,1,.193.9v.179h-.741a6.465,6.465,0,0,0-3.03.585A2.036,2.036,0,0,0-680.1,408.7a2.385,2.385,0,0,0,.215,1.06,1.986,1.986,0,0,0,.585.718,2.326,2.326,0,0,0,.83.415,3.648,3.648,0,0,0,.993.134A2.846,2.846,0,0,0-676,410.7a2.5,2.5,0,0,0,.822-.755v.9h2.578v-5.022a3.28,3.28,0,0,0-.245-1.327A2.3,2.3,0,0,0-673.546,403.587Zm-1.689,4.549a.962.962,0,0,1-.378.8,1.476,1.476,0,0,1-.941.3,1.046,1.046,0,0,1-.7-.193.667.667,0,0,1-.222-.533.674.674,0,0,1,.37-.659,2.768,2.768,0,0,1,1.17-.185h.7Z" transform="translate(693.073 -399.572)" />
                            <path id="Path_200303" data-name="Path 200303" d="M-666.892,408.808a.778.778,0,0,1-.585-.207.845.845,0,0,1-.2-.608v-3.2h1.526v-1.808h-1.526V401.37h-2.652v1.615h-.934v1.808h.934v3.452a2.622,2.622,0,0,0,.7,2.023,2.9,2.9,0,0,0,2.023.645,4.439,4.439,0,0,0,.844-.074c.247-.05.455-.1.622-.148v-2.015a1.555,1.555,0,0,1-.356.1A2.379,2.379,0,0,1-666.892,408.808Z" transform="translate(692.42 -399.46)" />
                            <path id="Path_200304" data-name="Path 200304" d="M-661.216,399.307a3.3,3.3,0,0,0-2.207.7,2.652,2.652,0,0,0-.83,2.142v.681h-.978v1.808h.978v5.942h2.636V404.64h1.571v-1.808h-1.571v-.681a.885.885,0,0,1,.207-.615.88.88,0,0,1,.682-.23,2.593,2.593,0,0,1,.4.029,2.924,2.924,0,0,1,.326.074v-1.941a5.006,5.006,0,0,0-.555-.119A4.377,4.377,0,0,0-661.216,399.307Z" transform="translate(691.974 -399.307)" />
                            <path id="Path_200305" data-name="Path 200305" d="M-652.13,403.957a3.831,3.831,0,0,0-1.342-.792,4.963,4.963,0,0,0-1.666-.274,4.915,4.915,0,0,0-1.682.281,3.821,3.821,0,0,0-1.342.815,3.783,3.783,0,0,0-.9,1.282,4.185,4.185,0,0,0-.326,1.682v.119a4.126,4.126,0,0,0,.326,1.674,3.5,3.5,0,0,0,.9,1.245,3.9,3.9,0,0,0,1.342.77,5.121,5.121,0,0,0,1.667.267,5,5,0,0,0,1.666-.274,3.986,3.986,0,0,0,1.357-.794,3.718,3.718,0,0,0,.889-1.267,4.214,4.214,0,0,0,.326-1.7v-.119a4.09,4.09,0,0,0-.326-1.659A3.751,3.751,0,0,0-652.13,403.957Zm-1.482,3.074a2.5,2.5,0,0,1-.385,1.5,1.325,1.325,0,0,1-1.141.525,1.34,1.34,0,0,1-1.156-.525,2.55,2.55,0,0,1-.385-1.528v-.118a2.4,2.4,0,0,1,.392-1.484,1.363,1.363,0,0,1,1.149-.509,1.326,1.326,0,0,1,1.141.523,2.5,2.5,0,0,1,.385,1.5Z" transform="translate(691.542 -399.572)" />
                            <path id="Path_200306" data-name="Path 200306" d="M-646.531,404.647v-1.541h-2.652v7.749h2.652v-3.674a1.448,1.448,0,0,1,.645-1.335,3.387,3.387,0,0,1,1.86-.37V403a2.692,2.692,0,0,0-1.519.378A2.694,2.694,0,0,0-646.531,404.647Z" transform="translate(690.787 -399.58)" />
                            <path id="Path_200307" data-name="Path 200307" d="M-630.437,404.513a2.767,2.767,0,0,0-.518-.912,2.177,2.177,0,0,0-.808-.533,2.826,2.826,0,0,0-1.015-.177,3.3,3.3,0,0,0-1.4.3,3.037,3.037,0,0,0-1.163.984,2.037,2.037,0,0,0-.808-.971,2.464,2.464,0,0,0-1.3-.318,2.615,2.615,0,0,0-1.511.415,2.862,2.862,0,0,0-.919.978V403.1h-2.652v7.749h2.652v-4.489a1.332,1.332,0,0,1,.326-.971,1.15,1.15,0,0,1,.86-.332.938.938,0,0,1,.726.288,1.262,1.262,0,0,1,.267.882v4.623h2.623v-4.489a1.333,1.333,0,0,1,.326-.971,1.149,1.149,0,0,1,.859-.332.94.94,0,0,1,.726.288,1.263,1.263,0,0,1,.267.882v4.623h2.652V405.78A3.788,3.788,0,0,0-630.437,404.513Z" transform="translate(690.295 -399.572)" />
                        </g>
                        <g id="Group_100933" data-name="Group 100933" transform="translate(4146.473 0.94)">
                            <path id="Path_200308" data-name="Path 200308" d="M-688.125,391.688l-2.03-5.646h-3.956v10.594h2.712v-6.594l2.563,6.594h1.008l2.593-6.594v6.594h3.023V386.042h-3.882Z" transform="translate(694.11 -385.361)" fill="#4c4c4c" />
                            <path id="Path_200309" data-name="Path 200309" d="M-673.5,389.587a3.062,3.062,0,0,0-1.1-.527,5.687,5.687,0,0,0-1.445-.17,5.863,5.863,0,0,0-1.46.17,3.37,3.37,0,0,0-1.133.511,2.679,2.679,0,0,0-.778.882,2.893,2.893,0,0,0-.333,1.222h2.489a1.2,1.2,0,0,1,.274-.645.966.966,0,0,1,.778-.274c.425,0,.7.111.83.334a1.793,1.793,0,0,1,.193.9v.179h-.741a6.465,6.465,0,0,0-3.03.585,2.036,2.036,0,0,0-1.089,1.947,2.385,2.385,0,0,0,.215,1.06,1.977,1.977,0,0,0,.585.718,2.323,2.323,0,0,0,.83.415,3.652,3.652,0,0,0,.993.134,2.847,2.847,0,0,0,1.474-.327,2.5,2.5,0,0,0,.822-.755v.9h2.578v-5.022a3.281,3.281,0,0,0-.245-1.327A2.305,2.305,0,0,0-673.5,389.587Zm-1.689,4.549a.963.963,0,0,1-.378.8,1.476,1.476,0,0,1-.941.3,1.045,1.045,0,0,1-.7-.193.667.667,0,0,1-.222-.533.673.673,0,0,1,.37-.659,2.769,2.769,0,0,1,1.17-.185h.7Z" transform="translate(693.07 -385.572)" fill="#4c4c4c" />
                            <path id="Path_200310" data-name="Path 200310" d="M-665.31,388.891a2.814,2.814,0,0,0-1.607.425,2.747,2.747,0,0,0-.941,1.036V389.1h-2.652v7.749h2.652v-4.329a1.492,1.492,0,0,1,.363-1.109,1.31,1.31,0,0,1,.956-.354,1.041,1.041,0,0,1,.844.317,1.507,1.507,0,0,1,.268.984v4.492h2.652v-5.038a3.039,3.039,0,0,0-.7-2.2A2.45,2.45,0,0,0-665.31,388.891Z" transform="translate(692.352 -385.572)" fill="#4c4c4c" />
                            <path id="Path_200311" data-name="Path 200311" d="M-655.716,393.575a1.518,1.518,0,0,1-.355,1.111,1.3,1.3,0,0,1-.963.356,1.015,1.015,0,0,1-.823-.327,1.489,1.489,0,0,1-.273-.978v-4.623h-2.652v5.052a3.693,3.693,0,0,0,.2,1.282,2.457,2.457,0,0,0,.556.9,2.076,2.076,0,0,0,.815.525,3.034,3.034,0,0,0,1.037.171,2.81,2.81,0,0,0,1.563-.385,2.583,2.583,0,0,0,.9-.993v1.2h2.653v-7.749h-2.653Z" transform="translate(691.627 -385.589)" fill="#4c4c4c" />
                            <path id="Path_200312" data-name="Path 200312" d="M-647.551,385.307a3.3,3.3,0,0,0-2.208.7,2.652,2.652,0,0,0-.83,2.142v.681h-.978v1.808h.978v5.941h2.636V390.64h1.571v-1.808h-1.571v-.681a.88.88,0,0,1,.208-.615.875.875,0,0,1,.681-.23,2.59,2.59,0,0,1,.4.029,3.035,3.035,0,0,1,.327.074v-1.941a5.109,5.109,0,0,0-.557-.119A4.37,4.37,0,0,0-647.551,385.307Z" transform="translate(690.94 -385.307)" fill="#4c4c4c" />
                            <path id="Path_200313" data-name="Path 200313" d="M-639.049,389.587a3.063,3.063,0,0,0-1.1-.527,5.688,5.688,0,0,0-1.445-.17,5.863,5.863,0,0,0-1.46.17,3.37,3.37,0,0,0-1.133.511,2.68,2.68,0,0,0-.778.882,2.9,2.9,0,0,0-.333,1.222h2.489a1.2,1.2,0,0,1,.274-.645.966.966,0,0,1,.778-.274q.636,0,.83.334a1.791,1.791,0,0,1,.193.9v.179h-.741a6.465,6.465,0,0,0-3.03.585A2.036,2.036,0,0,0-645.6,394.7a2.388,2.388,0,0,0,.215,1.06,1.978,1.978,0,0,0,.585.718,2.312,2.312,0,0,0,.83.415,3.647,3.647,0,0,0,.993.134,2.846,2.846,0,0,0,1.474-.327,2.5,2.5,0,0,0,.822-.755v.9h2.578v-5.022a3.283,3.283,0,0,0-.244-1.327A2.308,2.308,0,0,0-639.049,389.587Zm-1.689,4.549a.962.962,0,0,1-.378.8,1.475,1.475,0,0,1-.941.3,1.043,1.043,0,0,1-.7-.193.667.667,0,0,1-.222-.533.673.673,0,0,1,.37-.659,2.767,2.767,0,0,1,1.171-.185h.7Z" transform="translate(690.495 -385.572)" fill="#4c4c4c" />
                            <path id="Path_200314" data-name="Path 200314" d="M-631.354,394.758a1.311,1.311,0,0,1-.92.325,1.337,1.337,0,0,1-1.141-.511,2.535,2.535,0,0,1-.385-1.534v-.119a2.38,2.38,0,0,1,.385-1.474,1.308,1.308,0,0,1,1.1-.5,1.177,1.177,0,0,1,.867.29,1.485,1.485,0,0,1,.377.836h2.489a3.567,3.567,0,0,0-.393-1.5,3.025,3.025,0,0,0-.851-.993,3.432,3.432,0,0,0-1.178-.534,5.722,5.722,0,0,0-1.371-.162,4.625,4.625,0,0,0-1.608.274,3.9,3.9,0,0,0-1.311.792,3.714,3.714,0,0,0-.889,1.274,4.3,4.3,0,0,0-.326,1.719v.119a4.434,4.434,0,0,0,.311,1.72,3.361,3.361,0,0,0,.859,1.229,3.581,3.581,0,0,0,1.29.756,4.968,4.968,0,0,0,1.614.253,4.145,4.145,0,0,0,2.86-.882,3.2,3.2,0,0,0,1.036-2.348h-2.385A1.49,1.49,0,0,1-631.354,394.758Z" transform="translate(689.818 -385.572)" fill="#4c4c4c" />
                            <path id="Path_200315" data-name="Path 200315" d="M-623.18,394.808a.778.778,0,0,1-.585-.206.845.845,0,0,1-.2-.609v-3.2h1.526v-1.808h-1.526V387.37h-2.652v1.615h-.933v1.808h.933v3.452a2.618,2.618,0,0,0,.7,2.023,2.9,2.9,0,0,0,2.022.645,4.438,4.438,0,0,0,.845-.074c.247-.05.455-.1.622-.148v-2.015a1.562,1.562,0,0,1-.356.1A2.387,2.387,0,0,1-623.18,394.808Z" transform="translate(689.151 -385.46)" fill="#4c4c4c" />
                            <path id="Path_200316" data-name="Path 200316" d="M-615.892,393.575a1.519,1.519,0,0,1-.356,1.111,1.3,1.3,0,0,1-.963.356,1.014,1.014,0,0,1-.822-.327,1.489,1.489,0,0,1-.274-.978v-4.623h-2.651v5.052a3.69,3.69,0,0,0,.2,1.282,2.467,2.467,0,0,0,.556.9,2.091,2.091,0,0,0,.815.525,3.043,3.043,0,0,0,1.037.171,2.806,2.806,0,0,0,1.563-.385,2.577,2.577,0,0,0,.9-.993v1.2h2.652v-7.749h-2.652Z" transform="translate(688.659 -385.589)" fill="#4c4c4c" />
                            <path id="Path_200317" data-name="Path 200317" d="M-608.467,390.647v-1.541h-2.652v7.749h2.652v-3.674a1.448,1.448,0,0,1,.644-1.334,3.385,3.385,0,0,1,1.859-.37V389a2.693,2.693,0,0,0-1.519.378A2.692,2.692,0,0,0-608.467,390.647Z" transform="translate(687.927 -385.58)" fill="#4c4c4c" />
                            <rect id="Rectangle_18999" data-name="Rectangle 18999" width="2.652" height="7.749" transform="translate(83.043 3.526)" fill="#4c4c4c" />
                            <path id="Path_200318" data-name="Path 200318" d="M-603.075,385.354a1.452,1.452,0,0,0-1.037.393,1.3,1.3,0,0,0-.415.986,1.272,1.272,0,0,0,.415.977,1.463,1.463,0,0,0,1.037.385,1.489,1.489,0,0,0,1.045-.385,1.263,1.263,0,0,0,.422-.977,1.292,1.292,0,0,0-.422-.986A1.478,1.478,0,0,0-603.075,385.354Z" transform="translate(687.437 -385.31)" fill="#4c4c4c" />
                            <path id="Path_200319" data-name="Path 200319" d="M-594.734,388.891a2.815,2.815,0,0,0-1.608.425,2.744,2.744,0,0,0-.941,1.036V389.1h-2.652v7.749h2.652v-4.329a1.5,1.5,0,0,1,.363-1.109,1.309,1.309,0,0,1,.956-.354,1.045,1.045,0,0,1,.845.317,1.513,1.513,0,0,1,.267.984v4.492h2.652v-5.038a3.035,3.035,0,0,0-.7-2.2A2.449,2.449,0,0,0-594.734,388.891Z" transform="translate(687.086 -385.572)" fill="#4c4c4c" />
                            <path id="Path_200320" data-name="Path 200320" d="M-584.9,389.1V390.2a2.706,2.706,0,0,0-.9-.956,2.765,2.765,0,0,0-1.5-.358,3.19,3.19,0,0,0-1.282.259,3,3,0,0,0-1.045.748,3.616,3.616,0,0,0-.7,1.177,4.364,4.364,0,0,0-.259,1.549v.118a4.519,4.519,0,0,0,.259,1.585,3.424,3.424,0,0,0,.7,1.17,2.941,2.941,0,0,0,1.045.72,3.361,3.361,0,0,0,1.282.244,2.691,2.691,0,0,0,.808-.119,2.621,2.621,0,0,0,.689-.326,2.692,2.692,0,0,0,.526-.445,2.572,2.572,0,0,0,.378-.533v1.186a1.774,1.774,0,0,1-.378,1.177,1.57,1.57,0,0,1-1.267.452,1.474,1.474,0,0,1-1.03-.288,1.34,1.34,0,0,1-.393-.72h-2.637a3.346,3.346,0,0,0,.348,1.194,2.743,2.743,0,0,0,.778.911,3.545,3.545,0,0,0,1.215.569,6.472,6.472,0,0,0,1.719.2,4.983,4.983,0,0,0,3.193-.889,3.183,3.183,0,0,0,1.1-2.637v-7.1Zm.074,3.637a1.9,1.9,0,0,1-.415,1.3,1.42,1.42,0,0,1-1.126.472,1.375,1.375,0,0,1-1.111-.472,1.9,1.9,0,0,1-.4-1.269v-.119a1.976,1.976,0,0,1,.385-1.276,1.378,1.378,0,0,1,1.141-.479,1.391,1.391,0,0,1,1.133.472,1.922,1.922,0,0,1,.393,1.269Z" transform="translate(686.396 -385.572)" fill="#4c4c4c" />
                        </g>
                        <g id="Group_100934" data-name="Group 100934" transform="translate(4146.473 -11.971)">
                            <path id="Path_200321" data-name="Path 200321" d="M-691.088,378.369h3.423v-2.163h-3.423v-1.822h4.3v-2.341h-7.319v10.594h7.557V380.3h-4.534Z" transform="translate(694.11 -371.405)" fill="#7f7f7f" />
                            <path id="Path_200322" data-name="Path 200322" d="M-679.565,374.891a2.814,2.814,0,0,0-1.607.425,2.745,2.745,0,0,0-.941,1.036V375.1h-2.652v7.749h2.652v-4.329a1.5,1.5,0,0,1,.363-1.109,1.308,1.308,0,0,1,.956-.354,1.044,1.044,0,0,1,.844.317,1.512,1.512,0,0,1,.267.983v4.492h2.652v-5.038a3.035,3.035,0,0,0-.7-2.2A2.45,2.45,0,0,0-679.565,374.891Z" transform="translate(693.419 -371.616)" fill="#7f7f7f" />
                            <path id="Path_200323" data-name="Path 200323" d="M-669.735,376.2a2.7,2.7,0,0,0-.9-.956,2.764,2.764,0,0,0-1.5-.358,3.2,3.2,0,0,0-1.282.259,2.993,2.993,0,0,0-1.045.748,3.627,3.627,0,0,0-.7,1.177,4.367,4.367,0,0,0-.259,1.549v.118a4.523,4.523,0,0,0,.259,1.585,3.435,3.435,0,0,0,.7,1.17,2.941,2.941,0,0,0,1.045.719,3.366,3.366,0,0,0,1.282.244,2.694,2.694,0,0,0,.807-.119,2.623,2.623,0,0,0,.689-.326,2.656,2.656,0,0,0,.526-.445,2.5,2.5,0,0,0,.378-.533v1.186a1.773,1.773,0,0,1-.378,1.177,1.568,1.568,0,0,1-1.267.452,1.476,1.476,0,0,1-1.03-.288,1.346,1.346,0,0,1-.393-.72h-2.636a3.362,3.362,0,0,0,.347,1.194,2.767,2.767,0,0,0,.778.911,3.547,3.547,0,0,0,1.215.569,6.478,6.478,0,0,0,1.719.2,4.981,4.981,0,0,0,3.193-.889,3.184,3.184,0,0,0,1.1-2.637v-7.1h-2.652Zm.074,2.531a1.9,1.9,0,0,1-.414,1.3,1.422,1.422,0,0,1-1.127.472,1.375,1.375,0,0,1-1.111-.472,1.9,1.9,0,0,1-.4-1.269v-.119a1.982,1.982,0,0,1,.385-1.276,1.38,1.38,0,0,1,1.141-.479,1.389,1.389,0,0,1,1.133.472,1.917,1.917,0,0,1,.393,1.269Z" transform="translate(692.718 -371.616)" fill="#7f7f7f" />
                            <path id="Path_200324" data-name="Path 200324" d="M-663.523,371.354a1.453,1.453,0,0,0-1.037.393,1.3,1.3,0,0,0-.415.986,1.272,1.272,0,0,0,.415.977,1.464,1.464,0,0,0,1.037.385,1.49,1.49,0,0,0,1.045-.385,1.263,1.263,0,0,0,.422-.977,1.292,1.292,0,0,0-.422-.986A1.479,1.479,0,0,0-663.523,371.354Z" transform="translate(691.939 -371.354)" fill="#7f7f7f" />
                            <rect id="Rectangle_19000" data-name="Rectangle 19000" width="2.652" height="7.749" transform="translate(27.098 3.482)" fill="#7f7f7f" />
                            <path id="Path_200325" data-name="Path 200325" d="M-655.182,374.891a2.815,2.815,0,0,0-1.607.425,2.745,2.745,0,0,0-.941,1.036V375.1h-2.652v7.749h2.652v-4.329a1.5,1.5,0,0,1,.363-1.109,1.31,1.31,0,0,1,.956-.354,1.044,1.044,0,0,1,.845.317,1.512,1.512,0,0,1,.267.983v4.492h2.652v-5.038a3.036,3.036,0,0,0-.7-2.2A2.45,2.45,0,0,0-655.182,374.891Z" transform="translate(691.596 -371.616)" fill="#7f7f7f" />
                            <path id="Path_200326" data-name="Path 200326" d="M-644.075,375.854a3.558,3.558,0,0,0-1.26-.727,4.762,4.762,0,0,0-1.511-.236,4.768,4.768,0,0,0-1.645.281,3.821,3.821,0,0,0-1.333.815,3.875,3.875,0,0,0-.889,1.282,4.17,4.17,0,0,0-.326,1.682v.118a4.248,4.248,0,0,0,.319,1.69,3.487,3.487,0,0,0,.882,1.244,3.611,3.611,0,0,0,1.319.764,5.282,5.282,0,0,0,1.689.259,4.665,4.665,0,0,0,2.756-.712,2.84,2.84,0,0,0,1.17-2.029h-2.489a1.129,1.129,0,0,1-.378.651,1.452,1.452,0,0,1-.97.267,1.528,1.528,0,0,1-1.134-.422,1.815,1.815,0,0,1-.482-1.192h5.453v-.7a4.606,4.606,0,0,0-.319-1.793A3.5,3.5,0,0,0-644.075,375.854Zm-4.253,2.237a1.809,1.809,0,0,1,.489-1.082,1.372,1.372,0,0,1,.993-.371,1.283,1.283,0,0,1,.993.371,1.652,1.652,0,0,1,.37,1.082Z" transform="translate(690.899 -371.616)" fill="#7f7f7f" />
                            <path id="Path_200327" data-name="Path 200327" d="M-634.7,375.854a3.548,3.548,0,0,0-1.258-.727,4.772,4.772,0,0,0-1.511-.236,4.773,4.773,0,0,0-1.646.281,3.841,3.841,0,0,0-1.334.815,3.89,3.89,0,0,0-.889,1.282,4.183,4.183,0,0,0-.326,1.682v.118a4.233,4.233,0,0,0,.32,1.69,3.485,3.485,0,0,0,.88,1.244,3.622,3.622,0,0,0,1.32.764,5.276,5.276,0,0,0,1.688.259,4.661,4.661,0,0,0,2.756-.712,2.84,2.84,0,0,0,1.171-2.029h-2.489a1.132,1.132,0,0,1-.379.651,1.448,1.448,0,0,1-.97.267,1.53,1.53,0,0,1-1.134-.422,1.824,1.824,0,0,1-.482-1.192h5.453v-.7a4.587,4.587,0,0,0-.319-1.793A3.48,3.48,0,0,0-634.7,375.854Zm-4.251,2.237a1.806,1.806,0,0,1,.488-1.082,1.375,1.375,0,0,1,.994-.371,1.283,1.283,0,0,1,.993.371,1.648,1.648,0,0,1,.37,1.082Z" transform="translate(690.2 -371.616)" fill="#7f7f7f" />
                            <path id="Path_200328" data-name="Path 200328" d="M-629.188,376.647v-1.541h-2.651v7.749h2.651v-3.674a1.446,1.446,0,0,1,.645-1.334,3.384,3.384,0,0,1,1.859-.37V375a2.7,2.7,0,0,0-1.519.378A2.692,2.692,0,0,0-629.188,376.647Z" transform="translate(689.469 -371.624)" fill="#7f7f7f" />
                            <path id="Path_200329" data-name="Path 200329" d="M-623.8,371.354a1.453,1.453,0,0,0-1.037.393,1.3,1.3,0,0,0-.415.986,1.273,1.273,0,0,0,.415.977,1.464,1.464,0,0,0,1.037.385,1.487,1.487,0,0,0,1.044-.385,1.263,1.263,0,0,0,.422-.977,1.292,1.292,0,0,0-.422-.986A1.476,1.476,0,0,0-623.8,371.354Z" transform="translate(688.978 -371.354)" fill="#7f7f7f" />
                            <rect id="Rectangle_19001" data-name="Rectangle 19001" width="2.652" height="7.749" transform="translate(63.864 3.482)" fill="#7f7f7f" />
                            <path id="Path_200330" data-name="Path 200330" d="M-615.455,374.891a2.815,2.815,0,0,0-1.608.425,2.755,2.755,0,0,0-.941,1.036V375.1h-2.652v7.749H-618v-4.329a1.5,1.5,0,0,1,.363-1.109,1.31,1.31,0,0,1,.956-.354,1.044,1.044,0,0,1,.844.317,1.511,1.511,0,0,1,.267.983v4.492h2.652v-5.038a3.035,3.035,0,0,0-.7-2.2A2.448,2.448,0,0,0-615.455,374.891Z" transform="translate(688.634 -371.616)" fill="#7f7f7f" />
                            <path id="Path_200331" data-name="Path 200331" d="M-605.624,376.2a2.7,2.7,0,0,0-.9-.956,2.763,2.763,0,0,0-1.5-.358,3.194,3.194,0,0,0-1.282.259,2.993,2.993,0,0,0-1.045.748,3.627,3.627,0,0,0-.7,1.177,4.365,4.365,0,0,0-.259,1.549v.118a4.521,4.521,0,0,0,.259,1.585,3.435,3.435,0,0,0,.7,1.17,2.94,2.94,0,0,0,1.045.719,3.365,3.365,0,0,0,1.282.244,2.7,2.7,0,0,0,.808-.119,2.62,2.62,0,0,0,.689-.326,2.65,2.65,0,0,0,.526-.445,2.53,2.53,0,0,0,.378-.533v1.186A1.773,1.773,0,0,1-606,383.4a1.569,1.569,0,0,1-1.267.452,1.476,1.476,0,0,1-1.03-.288,1.347,1.347,0,0,1-.393-.72h-2.637a3.384,3.384,0,0,0,.348,1.194,2.752,2.752,0,0,0,.778.911,3.544,3.544,0,0,0,1.215.569,6.481,6.481,0,0,0,1.719.2,4.983,4.983,0,0,0,3.193-.889,3.184,3.184,0,0,0,1.1-2.637v-7.1h-2.652Zm.074,2.531a1.9,1.9,0,0,1-.415,1.3,1.418,1.418,0,0,1-1.126.472,1.375,1.375,0,0,1-1.111-.472,1.9,1.9,0,0,1-.4-1.269v-.119a1.978,1.978,0,0,1,.385-1.276,1.38,1.38,0,0,1,1.141-.479,1.39,1.39,0,0,1,1.134.472,1.917,1.917,0,0,1,.393,1.269Z" transform="translate(687.929 -371.616)" fill="#7f7f7f" />
                            <path id="Path_200332" data-name="Path 200332" d="M-589.415,380.462l1.7-3.349h-2.311l-.771,1.511-1.333-1.63a4,4,0,0,0,1.592-1.119,2.342,2.342,0,0,0,.527-1.5,2.128,2.128,0,0,0-.267-1.074,2.393,2.393,0,0,0-.711-.778,3.269,3.269,0,0,0-1.022-.467,4.658,4.658,0,0,0-1.215-.156,4.775,4.775,0,0,0-1.319.179,3.335,3.335,0,0,0-1.082.518,2.5,2.5,0,0,0-.741.875,2.587,2.587,0,0,0-.268,1.2,2.946,2.946,0,0,0,.156.873,3.668,3.668,0,0,0,.5.979,4.692,4.692,0,0,0-1.726,1.3,2.806,2.806,0,0,0-.6,1.786,3.269,3.269,0,0,0,.266,1.348,2.838,2.838,0,0,0,.757,1.008,3.158,3.158,0,0,0,1.163.629,5.125,5.125,0,0,0,1.533.216,4.486,4.486,0,0,0,1.7-.274,4.853,4.853,0,0,0,1.164-.675l.622.77h3.452Zm-4.14.023a1.451,1.451,0,0,1-.57.11,1.3,1.3,0,0,1-1.008-.369,1.346,1.346,0,0,1-.342-.949,1.1,1.1,0,0,1,.208-.7,1.814,1.814,0,0,1,.5-.444l1.674,2.015A1.393,1.393,0,0,1-593.555,380.485Zm.933-5.183a2.175,2.175,0,0,1-.793.582,4.048,4.048,0,0,1-.445-.611,1.145,1.145,0,0,1-.163-.582.921.921,0,0,1,.243-.678.85.85,0,0,1,.631-.247.741.741,0,0,1,.57.232.833.833,0,0,1,.216.59A1.133,1.133,0,0,1-592.622,375.3Z" transform="translate(686.966 -371.394)" fill="#7f7f7f" />
                        </g>
                        <g id="Group_100935" data-name="Group 100935" transform="translate(4146.532 -24)">
                            <rect id="Rectangle_19002" data-name="Rectangle 19002" width="3.067" height="10.594" transform="translate(0 0.637)" fill="#b2b2b2" />
                            <path id="Path_200333" data-name="Path 200333" d="M-683.726,361.891a2.814,2.814,0,0,0-1.607.425,2.756,2.756,0,0,0-.942,1.036V362.1h-2.651v7.749h2.651v-4.329a1.491,1.491,0,0,1,.364-1.109,1.308,1.308,0,0,1,.956-.354,1.041,1.041,0,0,1,.844.317,1.507,1.507,0,0,1,.268.983v4.492h2.652v-5.038a3.039,3.039,0,0,0-.7-2.2A2.45,2.45,0,0,0-683.726,361.891Z" transform="translate(693.668 -358.616)" fill="#b2b2b2" />
                            <path id="Path_200334" data-name="Path 200334" d="M-675.388,367.808a.778.778,0,0,1-.585-.206.844.844,0,0,1-.2-.609v-3.2h1.526v-1.808h-1.526V360.37h-2.652v1.615h-.933v1.808h.933v3.452a2.62,2.62,0,0,0,.7,2.023,2.9,2.9,0,0,0,2.023.645,4.44,4.44,0,0,0,.845-.074c.247-.05.455-.1.622-.148v-2.015a1.563,1.563,0,0,1-.356.1A2.388,2.388,0,0,1-675.388,367.808Z" transform="translate(692.99 -358.503)" fill="#b2b2b2" />
                            <path id="Path_200335" data-name="Path 200335" d="M-666.587,362.854a3.56,3.56,0,0,0-1.259-.727,4.768,4.768,0,0,0-1.511-.236,4.764,4.764,0,0,0-1.645.281,3.823,3.823,0,0,0-1.334.815,3.861,3.861,0,0,0-.889,1.282,4.164,4.164,0,0,0-.326,1.682v.119a4.233,4.233,0,0,0,.319,1.69,3.478,3.478,0,0,0,.882,1.244,3.612,3.612,0,0,0,1.319.764,5.281,5.281,0,0,0,1.689.259,4.665,4.665,0,0,0,2.756-.712,2.84,2.84,0,0,0,1.17-2.029h-2.489a1.135,1.135,0,0,1-.378.651,1.453,1.453,0,0,1-.971.267,1.528,1.528,0,0,1-1.133-.422,1.814,1.814,0,0,1-.482-1.192h5.452v-.7a4.605,4.605,0,0,0-.319-1.793A3.5,3.5,0,0,0-666.587,362.854Zm-4.252,2.237a1.8,1.8,0,0,1,.489-1.082,1.373,1.373,0,0,1,.993-.371,1.283,1.283,0,0,1,.993.371,1.652,1.652,0,0,1,.37,1.082Z" transform="translate(692.531 -358.616)" fill="#b2b2b2" />
                            <rect id="Rectangle_19003" data-name="Rectangle 19003" width="2.637" height="11.157" transform="translate(28.151 0.075)" fill="#b2b2b2" />
                            <rect id="Rectangle_19004" data-name="Rectangle 19004" width="2.637" height="11.157" transform="translate(32.329 0.075)" fill="#b2b2b2" />
                            <path id="Path_200336" data-name="Path 200336" d="M-653.316,358.354a1.453,1.453,0,0,0-1.037.393,1.3,1.3,0,0,0-.415.986,1.273,1.273,0,0,0,.415.977,1.464,1.464,0,0,0,1.037.385,1.488,1.488,0,0,0,1.045-.385,1.263,1.263,0,0,0,.422-.977,1.292,1.292,0,0,0-.422-.986A1.477,1.477,0,0,0-653.316,358.354Z" transform="translate(691.141 -358.354)" fill="#b2b2b2" />
                            <rect id="Rectangle_19005" data-name="Rectangle 19005" width="2.652" height="7.749" transform="translate(36.507 3.482)" fill="#b2b2b2" />
                            <path id="Path_200337" data-name="Path 200337" d="M-644.936,363.2a2.705,2.705,0,0,0-.9-.956,2.763,2.763,0,0,0-1.5-.358,3.192,3.192,0,0,0-1.282.259,2.994,2.994,0,0,0-1.044.748,3.629,3.629,0,0,0-.7,1.177,4.366,4.366,0,0,0-.259,1.549v.119a4.521,4.521,0,0,0,.259,1.585,3.438,3.438,0,0,0,.7,1.17,2.941,2.941,0,0,0,1.044.719,3.361,3.361,0,0,0,1.282.244,2.694,2.694,0,0,0,.808-.119,2.615,2.615,0,0,0,.689-.326,2.639,2.639,0,0,0,.526-.444,2.536,2.536,0,0,0,.378-.533v1.186a1.772,1.772,0,0,1-.378,1.177,1.569,1.569,0,0,1-1.267.452,1.475,1.475,0,0,1-1.03-.288,1.345,1.345,0,0,1-.393-.72h-2.637a3.383,3.383,0,0,0,.348,1.194,2.756,2.756,0,0,0,.778.911,3.549,3.549,0,0,0,1.215.569,6.479,6.479,0,0,0,1.719.2,4.983,4.983,0,0,0,3.193-.889,3.184,3.184,0,0,0,1.1-2.637v-7.1h-2.652Zm.074,2.531a1.9,1.9,0,0,1-.415,1.3,1.419,1.419,0,0,1-1.126.472,1.375,1.375,0,0,1-1.111-.472,1.9,1.9,0,0,1-.4-1.269v-.119a1.977,1.977,0,0,1,.385-1.276,1.38,1.38,0,0,1,1.141-.479,1.39,1.39,0,0,1,1.133.472,1.922,1.922,0,0,1,.393,1.269Z" transform="translate(690.836 -358.616)" fill="#b2b2b2" />
                            <path id="Path_200338" data-name="Path 200338" d="M-633.6,362.854a3.558,3.558,0,0,0-1.26-.727,4.766,4.766,0,0,0-1.511-.236,4.767,4.767,0,0,0-1.645.281,3.821,3.821,0,0,0-1.334.815,3.876,3.876,0,0,0-.889,1.282,4.169,4.169,0,0,0-.326,1.682v.119a4.25,4.25,0,0,0,.319,1.69A3.491,3.491,0,0,0-639.36,369a3.615,3.615,0,0,0,1.319.764,5.282,5.282,0,0,0,1.689.259,4.666,4.666,0,0,0,2.756-.712,2.841,2.841,0,0,0,1.171-2.029h-2.489a1.13,1.13,0,0,1-.378.651,1.453,1.453,0,0,1-.971.267,1.528,1.528,0,0,1-1.134-.422,1.816,1.816,0,0,1-.482-1.192h5.453v-.7a4.607,4.607,0,0,0-.318-1.793A3.5,3.5,0,0,0-633.6,362.854Zm-4.252,2.237a1.808,1.808,0,0,1,.489-1.082,1.373,1.373,0,0,1,.993-.371,1.282,1.282,0,0,1,.993.371,1.654,1.654,0,0,1,.371,1.082Z" transform="translate(690.091 -358.616)" fill="#b2b2b2" />
                            <path id="Path_200339" data-name="Path 200339" d="M-625.536,361.891a2.815,2.815,0,0,0-1.608.425,2.745,2.745,0,0,0-.941,1.036V362.1h-2.652v7.749h2.652v-4.329a1.5,1.5,0,0,1,.363-1.109,1.309,1.309,0,0,1,.956-.354,1.044,1.044,0,0,1,.845.317,1.512,1.512,0,0,1,.267.983v4.492H-623v-5.038a3.036,3.036,0,0,0-.7-2.2A2.45,2.45,0,0,0-625.536,361.891Z" transform="translate(689.364 -358.616)" fill="#b2b2b2" />
                            <path id="Path_200340" data-name="Path 200340" d="M-616.8,367.778a2.373,2.373,0,0,1-.4.03.775.775,0,0,1-.584-.206.84.84,0,0,1-.2-.609v-3.2h1.526v-1.808h-1.526V360.37h-2.653v1.615h-.932v1.808h.932v3.452a2.619,2.619,0,0,0,.7,2.023,2.893,2.893,0,0,0,2.022.645,4.419,4.419,0,0,0,.844-.074c.247-.05.455-.1.623-.148v-2.015A1.583,1.583,0,0,1-616.8,367.778Z" transform="translate(688.686 -358.503)" fill="#b2b2b2" />
                        </g>
                    </g>
                </svg>
            </>
        );
    }

    return render();
}

export default Logo;
