import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme/MainTheme';

const useStyles = makeStyles({
    loading_icon: {
        position: 'absolute',
        left: '75%',
        top: '50%',
    },

    deleteValidation_typography: {
        margin: theme.spacing(4)
    },

    button_container: {
        ...theme.flex.row,
        justifyContent: 'flex-end',
        margin: theme.spacing(2)
    },

    error: {
        color: theme.palette.error,
        padding: `${theme.spacing(1)} 0`,
        fontSize: theme.spacing(4),
    },

    editValidation_textfield: {
        margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
    },
});

export default useStyles;
