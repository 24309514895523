import { createSlice } from '@reduxjs/toolkit';
import callApi from '../../../app/api';
import store from '../../../app/store';

const initialState = {
    list: null,
    error: null,
    filtering: {},
    sorting: {},
    paging: {
        page: 1,
        pageSize: 1000,
    },
    loading: true,
    editPopupVisible: false,
    mainValidationData: null,
    editDataError: false,
    deletePopupVisible: false,
    deleteValidationError: false,
    writeAccess: false
};

const validationsSlice = createSlice({
    name: 'mainValidations',
    initialState,
    reducers: {
        fetchValidationsSuccess: (state, action) => {
            state.list = action.payload.items;
            state.paging = action.payload.paging;
            state.error = null;
            state.loading = false;
        },
        fetchValidationsError: (state, action) => {
            state.list = null;
            state.error = action.payload;
            state.loading = false;
        },
        setMainValidationsFilter: (state, action) => {
            const { key, value } = action.payload;
            if (value === '') {
                delete state.filtering[key];
            } else {
                state.filtering[key] = value;
            }
        },
        setSorting: (state, action) => {
            state.sorting = {
                [action.payload.name]: action.payload.direction,
            };
        },
        setMainValidationData: (state, action) => {
            state.mainValidationData = action.payload;
        },
        setMainValidationLoading: (state, action) => {
            state.loading = action.payload;
        },
        selectMainValidationsPage: (state, action) => {
            state.paging.page = action.payload;
        },
        selectMainValidationsRow: (state, action) => {
            state.list.forEach((row) => {
                if (row.id === action.payload) {
                    row.selected = true;
                } else {
                    delete row.selected;
                }
            });
        },

        openMainValidationsEditPopup: (state) => {
            state.editPopupVisible = true;
        },
        closeMainValidationsEditPopUp: (state) => {
            state.editPopupVisible = false;
            state.mainValidationData = null;
            state.editDataError = false;
        },
        editMainValidationData: (state, action) => {
            state.mainValidationData = { ...state.mainValidationData, ...action.payload };
        },
        editMainValidationDataError: (state) => {
            state.editDataError = true;
        },
        dismissEditMainValidationsError: (state) => {
            state.editDataError = false;
        },
        openMainValidationsDeletePopUp: (state) => {
            state.deletePopupVisible = true;
        },
        closeMainValidationsDeleteConfirmPopUp: (state) => {
            state.deletePopupVisible = false;
            state.deleteValidationError = false;
        },
        deleteMainValidationError: (state) => {
            state.deleteValidationError = true;
        },
        dismissDeleteMainValidationError: (state) => {
            state.deleteValidationError = false;
        },
        setWriteAccess: (state, action) => {
            state.writeAccess = action.payload;
        },
    },
});

export const {
    fetchValidationsSuccess,
    fetchValidationsError,
    setMainValidationsFilter,
    setSorting,
    setMainValidationData,
    setMainValidationLoading,
    selectMainValidationsPage,
    selectMainValidationsRow,
    openMainValidationsEditPopup,
    closeMainValidationsEditPopUp,
    editMainValidationData,
    editMainValidationDataError,
    dismissEditMainValidationsError,
    openMainValidationsDeletePopUp,
    closeMainValidationsDeleteConfirmPopUp,
    deleteMainValidationError,
    dismissDeleteMainValidationError,
    setWriteAccess
} = validationsSlice.actions;

export const fetchValidations = () => async (dispatch, state) => {
    let { filtering, sorting, paging } = store.getState().validations.mainValidations;
    paging = { page: paging.page, pageSize: paging.pageSize };
    try {
        const list = await callApi('POST', 'validations', JSON.stringify({ filtering, sorting, paging }));
        dispatch(fetchValidationsSuccess(list));

        await callApi('GET', 'get_write_access', { workspace: state().projects.workspaceID }).then((response) => {
            dispatch(setWriteAccess(response.access));
        });
    } catch (err) {
        dispatch(fetchValidationsError(err.toString()));
    }
};

export const filterMainValidations = (filter) => (dispatch) => {
    dispatch(setMainValidationsFilter(filter));
    dispatch(fetchValidations());
};

export const sortMainValidations = (name, direction) => {
    store.dispatch(setSorting({ name, direction }));
    store.dispatch(selectMainValidationsPage(1));
    store.dispatch(fetchValidations());
};

export const saveMainValidationData = (mainValidationData) => async (dispatch) => {
    dispatch(dismissEditMainValidationsError());
    try {
        const response = await callApi(mainValidationData.id ? 'PATCH' : 'PUT', mainValidationData.id ? `validations/${mainValidationData.id}` : 'validations', JSON.stringify(mainValidationData));

        if (response.status > 399) {
            dispatch(editMainValidationDataError());
            return;
        }

        dispatch(closeMainValidationsEditPopUp());
        dispatch(fetchValidations());
    } catch (err) {
        dispatch(editMainValidationDataError());
    }
};

export const deleteMainValidation = (id) => async (dispatch) => {
    dispatch(dismissDeleteMainValidationError());
    try {
        const response = await callApi('DELETE', `validations/${id}`);

        if (response.status > 399) {
            dispatch(deleteMainValidationError());
            return;
        }

        dispatch(closeMainValidationsDeleteConfirmPopUp());
        dispatch(fetchValidations());
    } catch (err) {
        dispatch(deleteMainValidationError());
    }
};

export default validationsSlice.reducer;
