import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { fetchValidations, filterMainValidations, openMainValidationsEditPopup, selectMainValidationsPage, selectMainValidationsRow, setMainValidationData, setMainValidationLoading } from './mainValidationsSlice';
import Table from '../../../components/Table/Table';
import columns from './columns';
import EditMainValidations from './EditMainValidations';
import DeleteMainValidation from './DeleteMainValidation';
import { setSubValidationsParent, setSubValidationLoading } from '../SubValidations/subValidationsSlice';
import LoadingIcon from '../../../components/LoadingIcon/LoadingIcon';
import useStyles from './style';

function MainValidations() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { list, paging, loading, mainValidationData, error, editPopupVisible, deletePopupVisible, writeAccess } = useSelector((state) => state.validations.mainValidations);

    useEffect(() => {
        dispatch(fetchValidations());
    }, [dispatch]);

    const onRowClick = (x) => {
        if (!mainValidationData?.id || mainValidationData?.id !== x.row.id) {
            let validationData = {};
            validationData.id = x.row.id;
            validationData.category = x.row.category;
            validationData.code = x.row.code;
            validationData.text = x.row.text;

            dispatch(setMainValidationData(validationData));
            dispatch(selectMainValidationsRow(x.row.id));
            dispatch(setSubValidationsParent(x.row));
            dispatch(setSubValidationLoading(true));
        }
    };

    const onPageChange = (x) => {
        dispatch(setSubValidationsParent(null));
        dispatch(setMainValidationLoading(true));
        dispatch(selectMainValidationsPage(x + 1));
        dispatch(fetchValidations());
    };

    const onSearchSubmit = (e) => {
        e.preventDefault();
        const filter = { key: 'code', value: e.target[0].value };
        dispatch(filterMainValidations(filter));
    };

    const openEditPopUp = () => {
        dispatch(setMainValidationData(null));
        dispatch(openMainValidationsEditPopup({}));
    };

    const renderLoading = () => <LoadingIcon className={classes.loading_icon} />;

    const renderError = () => <Alert severity="error">There was an error while fetching data from the server.</Alert>;

    const renderContent = () => {
        if (list) {
            return (
                <>
                    <Button variant="contained" color="primary" disabled={!writeAccess} onClick={openEditPopUp}>Add new</Button>
                    <Table data={list} columns={columns(writeAccess)} onRowClick={onRowClick} />
                </>
            );
        }
    };

    if (loading) {
        return renderLoading();
    }

    if (error) {
        return renderError();
    }

    return (
        <>
            {renderContent()}
            {editPopupVisible && <EditMainValidations />}
            {deletePopupVisible && <DeleteMainValidation />}
        </>
    );
}

MainValidations.propTypes = {};

export default MainValidations;
