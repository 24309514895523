import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';

function InfoLabel({ title, value, className }) {
    const classes = useStyles();
    function render() {
        if (!value) {
            return <></>;
        }
        return (
            <div className={classNames(classes.infoLabel_container, className)}>
                <div className={classes.infoLabel_title}>
                    <Typography variant="body1">
                        {title}
                    </Typography>
                    <Typography variant="body1">
                        :
                    </Typography>
                </div>
                <Typography variant="body1" className={classes.infoLabel_value}>
                    <b>
                        {value}
                    </b>
                </Typography>
            </div>
        );
    }
    return render();
}

InfoLabel.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
};

export default InfoLabel;
