import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    container: {
        padding: theme.spacing(5),
        width: '100%',
        backgroundColor: theme.palette.primary.grey,
    },

    content: {
        ...theme.flex.row,
    },

    column: {
        width: '100%',
        margin: theme.spacing(2),
    },
});

export default useStyles;
