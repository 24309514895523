import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { closeSubValidationsEditPopUp, saveSubValidationData } from './subValidationsSlice';
import InfoLabel from '../../../components/InfoLabel/InfoLabel';
import PopUp from '../../../components/PopUp/PopUp';
import useStyles from './style';

function EditSubValidations() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { list, parent, subValidationData, editDataError, editPopupVisible } = useSelector((state) => state.validations.subValidations);
    const [validationData, setValidationData] = useState(subValidationData);
    const [existingValidation, setExistingValidation] = useState(false);

    useEffect(() => {
        if (!subValidationData?.id) {
            setExistingValidation(false);
            list.forEach(x => {
                if (x.code === validationData?.code) {
                    setExistingValidation(true);
                }
            });
        }
    }, [validationData]);

    const closePopUp = () => {
        dispatch(closeSubValidationsEditPopUp());
    };

    const saveValidationsData = () => {
        dispatch(saveSubValidationData({ ...validationData, category: parent.category, codeParent: parent.code }));
    };

    const saveDisabled = existingValidation || !validationData?.code || validationData.code.trim().length === 0;

    return (
        <PopUp title={subValidationData?.id ? 'Edit Sub Validation' : 'New Sub Validation'} onClose={closePopUp} open={editPopupVisible} maxWidth="sm">
            <InfoLabel title="Category" value={parent.category} className={classes.editValidation_textfield} />
            <InfoLabel title="Parent code" value={parent.code} className={classes.editValidation_textfield} />
            {subValidationData?.id
                ? <InfoLabel title="Code" value={subValidationData?.code} className={classes.editValidation_textfield} />
                 : <TextField className={classes.editValidation_textfield} defaultValue={subValidationData?.code} label="Code*" onChange={(e) => setValidationData({ ...validationData, code: e.target.value })} />}
            <TextField className={classes.editValidation_textfield} defaultValue={subValidationData?.text} label="Text" onChange={(e) => setValidationData({ ...validationData, text: e.target.value })} />
            <TextField className={classes.editValidation_textfield} defaultValue={subValidationData?.mainVersion} label="Main" onChange={(e) => setValidationData({ ...validationData, mainVersion: e.target.value })} />
            <TextField
              className={classes.editValidation_textfield}
              value={validationData?.year}
              label="Year"
              type="number"
              onChange={(e) => (e.target.value > 0 && e.target.value <= new Date().getFullYear()
                ? setValidationData({ ...validationData, year: e.target.value })
                : setValidationData({ ...validationData, year: new Date().getFullYear() }))}
                />
            <TextField className={classes.editValidation_textfield} defaultValue={subValidationData?.vendor} label="Vendor" onChange={(e) => setValidationData({ ...validationData, vendor: e.target.value })} />
            {existingValidation && <Alert severity="warning">A validation already exists with this category and code!</Alert>}
            {editDataError && <Alert severity="error">The validation could not be added/modified.</Alert>}
            <div className={classes.button_container}>
                <Button variant="contained" color="primary" disabled={saveDisabled} onClick={saveValidationsData}>Save</Button>
            </div>
        </PopUp>
    );
}

EditSubValidations.propTypes = {};

export default EditSubValidations;
