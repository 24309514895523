import { createSlice } from '@reduxjs/toolkit';
import callApi from '../../../app/api';
import store from '../../../app/store';

const initialState = {
    key: new Date(),
    parent: null,
    list: null,
    error: null,
    filtering: {},
    sorting: {},
    paging: {
        page: 1,
        pageSize: 1000,
    },
    loading: false,
    editPopupVisible: false,
    subValidationData: null,
    editDataError: false,
    deletePopupVisible: false,
    deleteValidationError: false,
    writeAccess: false
};

const subValidationsSlice = createSlice({
    name: 'subValidations',
    initialState,
    reducers: {
        setSubValidationsParent: (state, action) => {
            state.parent = action.payload;
            state.filtering = {};
            state.paging.page = 1;
        },
        fetchSubValidationsSuccess: (state, action) => {
            state.list = action.payload.items;
            state.paging = action.payload.paging;
            state.error = null;
            state.loading = false;
        },
        fetchSubValidationsError: (state, action) => {
            state.list = null;
            state.error = action.payload;
            state.loading = false;
        },
        setSubValidationsFilter: (state, action) => {
            const { key, value } = action.payload;
            if (value === '') {
                delete state.filtering[key];
            } else {
                state.filtering[key] = value;
            }
        },
        resetFilters: (state) => {
            state.key = new Date();
        },
        setSorting: (state, action) => {
            state.sorting = {
                [action.payload.name]: action.payload.direction,
            };
        },
        setSubValidationData: (state, action) => {
            state.subValidationData = action.payload;
        },
        setSubValidationLoading: (state, action) => {
            state.loading = action.payload;
        },
        selectSubValidationsPage: (state, action) => {
            state.paging.page = action.payload;
        },
        openSubValidationsEditPopup: (state) => {
            state.editPopupVisible = true;
        },
        closeSubValidationsEditPopUp: (state) => {
            state.editPopupVisible = false;
            state.subValidationData = null;
            state.editDataError = false;
        },
        editSubValidationData: (state, action) => {
            state.subValidationData = { ...state.subValidationData, ...action.payload };
        },
        editSubValidationDataError: (state) => {
            state.editDataError = true;
        },
        dismissEditSubValidationsError: (state) => {
            state.editDataError = false;
        },
        openSubValidationsDeletePopUp: (state) => {
            state.deletePopupVisible = true;
        },
        closeSubValidationsDeleteConfirmPopUp: (state) => {
            state.deletePopupVisible = false;
            state.deleteValidationError = false;
        },
        deleteSubValidationError: (state) => {
            state.deleteValidationError = true;
        },
        dismissDeleteSubValidationError: (state) => {
            state.deleteValidationError = false;
        },
        setWriteAccess: (state, action) => {
            state.writeAccess = action.payload;
        },
    },
});

export const {
    setSubValidationsParent,
    fetchSubValidationsSuccess,
    fetchSubValidationsError,
    setSubValidationsFilter,
    resetFilters,
    setSorting,
    setSubValidationData,
    setSubValidationLoading,
    selectSubValidationsPage,
    openSubValidationsEditPopup,
    closeSubValidationsEditPopUp,
    editSubValidationData,
    editSubValidationDataError,
    dismissEditSubValidationsError,
    openSubValidationsDeletePopUp,
    closeSubValidationsDeleteConfirmPopUp,
    deleteSubValidationError,
    dismissDeleteSubValidationError,
    setWriteAccess
} = subValidationsSlice.actions;

export const fetchSubValidations = () => async (dispatch, state) => {
    let { parent, filtering, sorting, paging } = store.getState().validations.subValidations;
    paging = { page: paging.page, pageSize: paging.pageSize };
    try {
        const list = await callApi('POST', 'validations', JSON.stringify({
            codeParent: parent.code,
            filtering,
            sorting,
            paging,
        }));
        dispatch(fetchSubValidationsSuccess(list));

        await callApi('GET', 'get_write_access', { workspace: state().projects.workspaceID }).then((response) => {
            dispatch(setWriteAccess(response.access));
        });
    } catch (err) {
        dispatch(fetchSubValidationsError(err.toString()));
    }
};

export const filterSubValidations = (filter) => (dispatch) => {
    dispatch(setSubValidationsFilter(filter));
    dispatch(fetchSubValidations());
};

export const sortSubValidations = (name, direction) => {
    store.dispatch(setSorting({ name, direction }));
    store.dispatch(selectSubValidationsPage(1));
    store.dispatch(fetchSubValidations());
};

export const saveSubValidationData = (subValidationData) => async (dispatch) => {
    dispatch(dismissEditSubValidationsError());
    try {
        const response = await callApi(subValidationData.id ? 'PATCH' : 'PUT', subValidationData.id ? `validations/${subValidationData.id}` : 'validations', JSON.stringify(subValidationData));

        if (response.status > 399) {
            dispatch(editSubValidationDataError());
            return;
        }

        dispatch(closeSubValidationsEditPopUp());
        dispatch(fetchSubValidations());
    } catch (err) {
        dispatch(editSubValidationDataError());
    }
};

export const deleteSubValidation = (id) => async (dispatch) => {
    dispatch(dismissDeleteSubValidationError());
    try {
        const response = await callApi('DELETE', `validations/${id}`);

        if (response.status > 399) {
            dispatch(deleteSubValidationError());
            return;
        }

        dispatch(closeSubValidationsDeleteConfirmPopUp());
        dispatch(setSubValidationData(null));
        dispatch(fetchSubValidations());
    } catch (err) {
        dispatch(deleteSubValidationError());
    }
};

export default subValidationsSlice.reducer;
