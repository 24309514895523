import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme/MainTheme';

const useStyles = makeStyles({
    container: {
        padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
    },
});

export default useStyles;
