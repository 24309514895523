import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useStyles from './style';
import { ReactComponent as Logo } from './loading.svg';

function LoadingIcon({ center, className }) {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setVisible(true), 250);
        return () => clearTimeout(timeout);
    });

    if (!visible) return null;

    return (
        <div className={classNames(classes.container, { [classes.center]: center }, className)}>
            <Logo />
        </div>
    );
}

LoadingIcon.propTypes = {
    center: PropTypes.bool,
    className: PropTypes.string,
};

export default LoadingIcon;
