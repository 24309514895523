import { CallReceived } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const drawerWidth = '290px';
const useStyles = makeStyles({
    app_environment: {
        color: 'white',
        position: 'fixed',
        right: '0.6vmin',
        bottom: '0.6vmin'
    },
    menuItem: {
        textDecoration: 'none',
        color: 'white',
        '& .MuiListItem-button': {
            padding: 0,
            borderLeft: '0.55vw solid',
            borderRight: '0.55vw solid',
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
        },
        '& .MuiListItemText-root': {
            color: '#C5B5D7'
        },
    },
    disabled_menuItem: {
        textDecoration: 'none',
        color: 'white',
        '& .MuiListItem-button': {
            padding: 0,
            borderLeft: '0.55vw solid',
            borderRight: '0.55vw solid',
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
            cursor: 'default'
        },
        '& .MuiListItemText-root': {
            color: '#C5B5D7'
        },
    },
    selected_menuItem: {
        textDecoration: 'none',
        '& .MuiListItem-button': {
            padding: 0,
            borderLeft: '0.55vw solid',
            borderRight: '0.55vw solid',
            borderLeftColor: 'white',
            borderRightColor: 'transparent',
            background: 'rgba(255,255,255,0.3)'
        },
        '& .MuiListItemText-root': {
            color: 'white'
        },
        '& .MuiListItemIcon-root': {
            filter: 'brightness(0) invert(1)',
        }
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawer_paper: {
        background: 'linear-gradient(to bottom, #310777 20%, #B416A2 100%)',
        '& .MuiDivider-root': {
            background: 'rgba(255,255,255,0.3)',
            height: '0.1vh'
        },
        '& .MuiListItemText-root': {
            paddingLeft: '1.2vw',
        },
        '& .MuiListItemIcon-root': {
            width: `calc(${theme.sidebar} - 1.2vw)`,
            padding: theme.spacing(3.65, 0),
            ...theme.flex.row,
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    drawerOpen: {
        position: 'fixed',
        zIndex: 9999,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& $toolbar': {
            paddingLeft: theme.spacing(4),
            marginBottom: '3.15px'
        }
    },
    drawerClose: {
        position: 'fixed',
        zIndex: 9999,
        overflowX: 'hidden',
        width: theme.sidebar,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        '& $toolbar': {
            justifyContent: 'center'
        }
    },
    toolbar: {
        ...theme.flex.row,
        alignItems: 'center',
    },
});

export default useStyles;
