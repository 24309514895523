import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    add_project_dialog_content: {
        background: theme.palette.primary.grey,
        padding: theme.spacing(4, 8),
        '& input': {
            background: 'white'
        },
        '& .MuiInputBase-formControl': {
            background: 'white'
        }
    },
    add_project_dialog_content_upload: {
        ...theme.flex.row,
        width: '100%'
    },
    add_project_dialog_content_processing: {
        padding: theme.spacing(4, 8)
    },
    add_project_dialog_save_button: {
        ...theme.flex.row,
        justifyContent: 'flex-end',
        '& .MuiButton-root': {
            minWidth: '127px'
        }
    },
    add_project_dialog_upload_button: {
        ...theme.flex.column,
        margin: theme.spacing(0, 0, 8, 0),
        minHeight: '45.23px',
        '& .MuiButton-root': {
            background: 'white'
        },
        '& .MuiFormHelperText-root': {
            background: theme.palette.primary.grey,
            textAlign: 'left',
            color: 'black',
            marginRight: theme.spacing(1)
        }
    },
    add_project_dialog_input_header: {
        margin: theme.spacing(4, 0, 2, 0),
        color: 'black'
    },
    add_project_dialog_description: {
        ...theme.flex.column,
        margin: theme.spacing(2, 0)
    },
    add_project_dialog_project_name: {
        ...theme.flex.column,
        margin: theme.spacing(2, 0),
        '& .MuiFormHelperText-root': {
            background: theme.palette.primary.grey,
            textAlign: 'right',
            color: 'black',
            marginRight: theme.spacing(1)
        }
    },
});

export default useStyles;
