import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InfoLabel from '../../../components/InfoLabel/InfoLabel';
import { closeSubValidationsDeleteConfirmPopUp, deleteSubValidation } from './subValidationsSlice';
import PopUp from '../../../components/PopUp/PopUp';
import useStyles from './style';

function DeleteSubValidation() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { deletePopupVisible, subValidationData, deleteValidationError } = useSelector((state) => state.validations.subValidations);

    const closePopUp = () => {
        dispatch(closeSubValidationsDeleteConfirmPopUp());
    };

    const deleteValidation = () => {
        dispatch(deleteSubValidation(subValidationData?.id));
    };

    return (
        <PopUp title="Delete Sub Validation" onClose={closePopUp} open={deletePopupVisible} maxWidth="sm">
            <div className={classes.deleteValidation_typography}>
                <Typography variant="body1">Would you like to delete the following validation?</Typography>
                <InfoLabel title="Code" value={subValidationData?.code} />
                <InfoLabel title="Text" value={subValidationData?.text} />
                <InfoLabel title="Main Version" value={subValidationData?.mainVersion} />
                <InfoLabel title="Year" value={subValidationData?.year} />
                <InfoLabel title="Vendor" value={subValidationData?.vendor} />
            </div>
            {deleteValidationError && <Alert severity="error">The validation could not be deleted.</Alert>}
            <div className={classes.button_container}>
                <Button variant="contained" color="primary" onClick={deleteValidation}>Delete</Button>
            </div>
        </PopUp>
    );
}

export default DeleteSubValidation;
