import React from 'react';
import { Dialog, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './style';

export default function PopUp({ children, title, onClose, open, maxWidth }) {
    const classes = useStyles();

    return (
        <Dialog className={classes.project_dialog} fullWidth maxWidth={maxWidth || 'md'} onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle className={classes.project_dialog_title}>
                {title}
                <IconButton aria-label="close" className={classes.close_project_dialog_button} onClick={onClose}>
                    <CloseIcon />
                    {' '}
                </IconButton>
            </DialogTitle>
            {children}
        </Dialog>
    );
}
