import React from 'react';
import useStyles from './style';
import MainValidations from './MainValidations/MainValidations';
import SubValidations from './SubValidations/SubValidations';
import PageTitle from '../../components/PageTitle/PageTitle';

function Validations() {
    const classes = useStyles();
    return (
        <>
            <PageTitle label="Validations" />
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.column}>
                        <MainValidations />
                    </div>
                    <div className={classes.column}>
                        <SubValidations />
                    </div>
                </div>
            </div>
        </>
    );
}

Validations.propTypes = {};

export default Validations;
