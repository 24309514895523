import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import InfoLabel from '../../../components/InfoLabel/InfoLabel';
import PopUp from '../../../components/PopUp/PopUp';
import { closeMainValidationsEditPopUp, saveMainValidationData } from './mainValidationsSlice';
import useStyles from './style';

function EditMainValidations() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { list, mainValidationData, editDataError, editPopupVisible } = useSelector((state) => state.validations.mainValidations);
    const [validationData, setValidationData] = useState(mainValidationData);
    const [existingValidation, setExistingValidation] = useState(false);

    useEffect(() => {
        if (!mainValidationData?.id) {
            setExistingValidation(false);
            list.forEach(x => {
                if (x.category === validationData?.category && x.code === validationData?.code) {
                    setExistingValidation(true);
                }
            });
        }
    }, [validationData]);

    const closePopUp = () => {
        dispatch(closeMainValidationsEditPopUp());
    };

    const saveValidationsData = () => {
        dispatch(saveMainValidationData(validationData));
    };

    const saveDisabled = existingValidation || !validationData?.code || !validationData?.category || validationData.code.trim().length === 0 || validationData.category.trim().length === 0;

    function render() {
        return (
            <PopUp title={mainValidationData?.id ? 'Edit Main Validation' : 'New Main Validation'} onClose={closePopUp} open={editPopupVisible} maxWidth="sm">
                {mainValidationData?.id ? (
                    <>
                        <InfoLabel title="Category" value={mainValidationData?.category} className={classes.editValidation_textfield} />
                        <InfoLabel title="Code" value={mainValidationData?.code} className={classes.editValidation_textfield} />
                    </>
                ) : (
                    <>
                        <TextField className={classes.editValidation_textfield} defaultValue={mainValidationData?.category} label="Category*" onChange={(e) => setValidationData({ ...validationData, category: e.target.value })} />
                        <TextField className={classes.editValidation_textfield} defaultValue={mainValidationData?.code} label="Code*" onChange={(e) => setValidationData({ ...validationData, code: e.target.value })} />
                    </>
                )}
                <TextField className={classes.editValidation_textfield} defaultValue={mainValidationData?.text} label="Text" onChange={(e) => setValidationData({ ...validationData, text: e.target.value })} />
                {existingValidation && <Alert severity="warning">A validation already exists with this category and code!</Alert>}
                {editDataError && <Alert severity="error">The validation could not be added/modified.</Alert>}
                <div className={classes.button_container}>
                    <Button variant="contained" color="primary" disabled={saveDisabled} onClick={saveValidationsData}>Save</Button>
                </div>
            </PopUp>
        );
    }
    return render();
}

EditMainValidations.propTypes = {};

export default EditMainValidations;
