import { makeStyles } from '@material-ui/styles';
import flex from '../../theme/flex';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    centered_chip: {
        ...theme.flex.row,
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiChip-root ': {
            background: theme.palette.primary.main,
            cursor: 'pointer',
            height: '3vmin',
            width: '3vmin',
        },
        '& .MuiChip-label': {
            ...theme.flex.column,
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& span': {
            padding: theme.spacing(1),
        },
        '& img': {
            filter: 'invert(100%)',
            height: '2.4vmin',
        },
    },
    input_paper: {
        height: '40px',
        minWidth: '201px',
        width: '14vw',
        padding: theme.spacing(0, 1, 0, 2),
        ...theme.flex.row,
        justifyContent: 'space-between',
    },
    input_base: {},
    input_iconButton: {
        color: 'black',
    },
    customToolbar_container: {
        ...theme.flex.row,
        justifyContent: 'space-between',
        margin: theme.spacing(2, 0),
    },
    customToolbar_buttons: {
        ...theme.flex.row,
        justifyContent: 'flex-end',
    },
    customToolbar_panel: {
        fontSize: 0,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: 'fit-content',
    },
    customDatagrid_row: {
        ...theme.flex.row,
        alignItems: 'center',
        marginBottom: '10px',
        maxHeight: '74px !important',
        minHeight: '74px !important',
        background: 'white',
        boxShadow: theme.shadows[0],
        '& .MuiDataGrid-cell': {
            borderBottom: 'none',
        },
    },
    customDatagrid: {
        border: 'none',
        '& .MuiDataGrid-columnHeader': {
            padding: 0,
            '& .MuiDataGrid-iconButtonContainer': {
                display: 'none',
            },
            '& .MuiDataGrid-menuIcon': {
                display: 'none',
            },
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'space-between',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            color: 'black',
            fontSize: '1em',
            fontWeight: 600,
        },
        '& .MuiDataGrid-footerContainer': {
            justifyContent: 'center',
            position: 'sticky',
            left: '50vw',
            bottom: '4vh',
        },
        '& .MuiTablePagination-caption': {
            fontWeight: 800,
            fontSize: '1em',
        },
    },
    customSortIcon: {
        width: '1vw',
        ...theme.flex.row,
        justifyContent: 'center',
        alignItems: 'center',
    },
    export_button: {
        borderRadius: 0,
        borderLeft: '1.4px solid #cdcdcd',
        '& .MuiButton-label': {
            marginLeft: '0.8vw',
        },
    },
    container: {
        border: '1px solid #282c34',
        fontSize: '13px',
    },
    no_items: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(25),
    },
});

export default useStyles;
