import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Projects from '../../features/Projects/Projects';
import Validations from '../../features/Validations';
import Project from '../../features/Projects/Project';

function Routes() {
    return (
        <Switch>
            <Route path="/validations">
                <Validations />
            </Route>
            <Route path="/project/:projectId">
                <Project />
            </Route>
            <Route path="/">
                <Projects />
            </Route>
        </Switch>
    );
}

export default Routes;
