import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    project_dialog: {
        marginLeft: theme.sidebar
    },

    project_dialog_title: {
        background: '#350778',
        color: 'white',
        '& .MuiIconButton-label': {
            color: 'white'
        }
    },

    close_project_dialog_button: {
        position: 'absolute',
        right: theme.spacing(3),
        top: theme.spacing(2),
        color: 'black',
    },

});

export default useStyles;
