import React from 'react';
import PropTypes from 'prop-types';

class DynamicImport extends React.Component {
  state = {
    Component: null,
  };

  componentDidMount() {
    this.props.load().then(module => {
      this.setState({ Component: module.default || module });
    });
  }

  render() {
    const { Component } = this.state;
    if (!Component) return null;
    return <Component {...this.props} />;
  }
}

DynamicImport.propTypes = {
  load: PropTypes.func,
};

export default DynamicImport;
