import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    DataGrid,
    GridColumnHeaderTitle,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@material-ui/data-grid';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { Zoom } from 'react-awesome-reveal';
import ErrorNotice from '../ErrorNotice/ErrorNotice';
import ColumnsIcon from '../../resources/icons/Columns.svg';
import DensityIcon from '../../resources/icons/Density.svg';
import ExportIcon from '../../resources/icons/Export.svg';
import SortIcon from '../../resources/icons/Sort.svg';
import SortAscIcon from '../../resources/icons/SortAsc.svg';
import SortDescIcon from '../../resources/icons/SortDesc.svg';
import useStyles from './style';

function Table({ columns, data, noDataOverlay, onRowClick, rowCount, paginationMode, onPageChange, currentPage, onSearchSubmit }) {
    if (!columns) return null;
    const classes = useStyles();
    const defaultPageSize = 10;

    const [matchingData, setMatchingData] = useState(data || []);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => setMatchingData(data), [data]);

    function handleSearchSubmit(e) {
        e.preventDefault();
        const term = e.target[0].value;
        setSearchTerm(term);
        if (term === '') {
            setMatchingData(data);
            return;
        }

        let filteredData = [];
        data.forEach((r, i) => {
            if (Object.entries(r)
                .filter(([key, value]) => value !== null && isColumnsContain(key) && !isFieldHid(key))
                .filter(([key, value]) => value.toString().toLowerCase().includes(term?.toLowerCase())).length > 0) {
                filteredData.push(data[i]);
            }
        });
        setMatchingData(filteredData);
    }

    const isFieldHid = (field) => !!columns.filter(x => x.field === field)[0]?.hide;

    const isColumnsContain = (field) => columns.filter(x => x.field === field)?.length > 0;

    const loadIdToData = (data) => (data?.length > 0 && !data[0].id ? data.map((e, i) => ({ ...e, id: i })) : data);

    function CustomToolbar() {
        return (
            <GridToolbarContainer className={classes.customToolbar_container}>
                {renderSearchBar()}
                <div className={classes.customToolbar_buttons}>
                    <GridToolbarColumnsButton startIcon={<img src={ColumnsIcon} alt="Columns" />} className={classes.customToolbar_panel} />
                    <GridToolbarDensitySelector startIcon={<img src={DensityIcon} alt="Density" />} className={classes.customToolbar_panel} />
                    <GridToolbarExport
                      startIcon={<img src={ExportIcon} alt="Export" />}
                      className={clsx(classes.customToolbar_panel, classes.export_button)}
          />
                </div>
            </GridToolbarContainer>
        );
    }

    function renderCell(params, renderButton) {
        if (!params.value && !renderButton) {
            return <span> </span>;
        }
        if (renderButton) {
            const { disabled, avatar, onClick } = renderButton;
            return (
                <div className={classes.centered_chip}>
                    <Chip disabled={disabled} onClick={() => onClick()} label={<img src={avatar} alt="avatar" />} />
                </div>
            );
        }
        return (
            <Tooltip title={params.value} placement="top">
                <>{params.value}</>
            </Tooltip>
        );
    }

    function renderSearchBar() {
        if (paginationMode === 'server') {
            return (
                <Paper component="form" className={classes.input_paper} variant="outlined" onSubmit={onSearchSubmit}>
                    <InputBase className={classes.input_base} defaultValue="" autoFocus placeholder="Search" inputProps={{ 'aria-label': 'search google maps' }} />
                    <IconButton type="submit" className={classes.input_iconButton} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            );
        }

        return (
            <Paper component="form" className={classes.input_paper} variant="outlined" onSubmit={(e) => handleSearchSubmit(e)}>
                <InputBase className={classes.input_base} defaultValue={searchTerm} autoFocus placeholder="Search" inputProps={{ 'aria-label': 'search google maps' }} />
                <IconButton type="submit" className={classes.input_iconButton} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Paper>
        );
    }
    function renderHeaderSortIcon(sortDirection) {
        if (!sortDirection) {
            return (
                <div className={classes.customSortIcon}>
                    <img src={SortIcon} alt="Sort" />
                </div>
            );
        }
        if (sortDirection === 'asc') {
            return (
                <div className={classes.customSortIcon}>
                    <img src={SortAscIcon} alt="Sort" />
                </div>
            );
        }
        if (sortDirection === 'desc') {
            return (
                <div className={classes.customSortIcon}>
                    <img src={SortDescIcon} alt="Sort" />
                </div>
            );
        }
    }

    function renderHeader(params) {
        const { colDef, api, field } = params;
        const sort = api.state.sorting.sortModel.filter((s) => s.field === field)?.[0]?.sort;
        let sortDirection = null;
        if (sort) {
            sortDirection = sort;
        }
        return (
            <>
                <GridColumnHeaderTitle label={colDef.headerName} description={colDef.description} columnWidth={colDef.width} />
                {colDef.sortable && renderHeaderSortIcon(sortDirection)}
            </>
        );
    }

    function customRenderToColumns() {
        return columns.map((c) => ({
            ...c,
            renderHeader: (params) => renderHeader(params),
            renderCell: (params) => renderCell(params, c.renderButton),
        }));
    }

    function renderServerSide() {
        return (
            <DataGrid
              autoHeight
              rowHeight={84}
              pageSize={defaultPageSize}
              rowsPerPageOptions={[defaultPageSize]}
              page={currentPage}
              onPageChange={(x) => (onPageChange ? onPageChange(x) : {})}
              rowCount={rowCount}
              paginationMode="server"
              className={classes.customDatagrid}
              getRowClassName={() => classes.customDatagrid_row}
              getColumnClassName={() => classes.customDatagrid_column}
              columns={columns}
              rows={loadIdToData(data)}
              onRowClick={(x) => (onRowClick ? onRowClick(x) : {})}
              sortingOrder={['asc', 'desc']}
              components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: () => (noDataOverlay ? <Zoom className={classes.no_items}>{noDataOverlay()}</Zoom> : <div className={classes.no_items}><ErrorNotice label="No Data" /></div>),
        }}
      />
        );
    }

    function renderClientSide() {
        return (
            <DataGrid
              autoHeight
              rowHeight={84}
              pageSize={defaultPageSize}
              rowsPerPageOptions={[defaultPageSize]}
              className={classes.customDatagrid}
              getRowClassName={() => classes.customDatagrid_row}
              getColumnClassName={() => classes.customDatagrid_column}
              columns={columns}
              rows={loadIdToData(matchingData)}
              onRowClick={(x) => (onRowClick ? onRowClick(x) : {})}
              sortingOrder={['asc', 'desc']}
              components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: () => (noDataOverlay ? <Zoom className={classes.no_items}>{noDataOverlay()}</Zoom> : <div className={classes.no_items}><ErrorNotice label="No Data" /></div>),
        }}
      />
        );
    }

    function render() {
        columns = customRenderToColumns();

        return paginationMode === 'server' ? renderServerSide() : renderClientSide();
    }

    return render();
}

Table.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
};

export default Table;
