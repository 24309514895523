import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import DualListBox from 'react-dual-listbox';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import callApi from '../../app/api';
import ErrorNotice from '../ErrorNotice/ErrorNotice';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import Dropdown from '../DropDown/Dropdown';
import PopUp from '../PopUp/PopUp';
import useStyles from './style';

function MergeProjectsDialog(props) {
    const classes = useStyles();
    const [dualListBoxOptions, setDualListBoxOptions] = useState(props.projects
        .filter((x) => x.id !== props.projects[0].id)
        .map((x) => ({ value: x.id, label: x.name })));
    const [mainProject, setMainProject] = useState(props.projects[0]?.id);
    const [selected, setSelected] = useState([]);
    const [sentToProcess, setSentToProcess] = useState(false);
    const [processError, setProcessError] = useState('');

    const onMainProjectChange = (event) => {
        setMainProject(event.target.value);
        setDualListBoxOptions(
            props.projects
                .filter((x) => x.id !== event.target.value)
                .map((x) => ({ value: x.id, label: x.name }))
        );
    };

    const onDualListBoxChange = (selected) => {
        setSelected(selected);
    };

    const handleCloseDialog = () => {
        setProcessError('');
        props.onClose(false);
    };

    async function handleMergeProjectsSubmit(e) {
        e.preventDefault();
        setSentToProcess(true);

        const response = await callApi('POST', 'project-merge', JSON.stringify({ mainProject: e.target[0].value, projectsToMerge: selected })).then((resp) => {
            setSentToProcess(false);
            props.setUserNotice({ enabled: true, state: 'success', message: 'Projects merged successfully.' });
            props.onClose(true);
        }).catch(ex => {
            setProcessError(`${ex.message}`);
            setSentToProcess(false);
        });
    }

    const renderErrorNotice = () => (
        <div className={classes.merge_projects_dialog_content_processing}>
            <ErrorNotice label={processError} />
        </div>
    );

    const renderProcessing = () => (
        <div className={classes.merge_projects_dialog_content_processing}>
            <Typography variant="body1">
                {' '}
                Processing
                {' '}
            </Typography>
            <Typography variant="body2">Please wait and don&apos;t close the browser!</Typography>
            <LoadingIcon />
        </div>
    );

    const renderForm = () => (
        <Paper component="form" onSubmit={(e) => handleMergeProjectsSubmit(e)} className={classes.merge_projects_dialog_content}>
            <div className={classes.merge_projects_dialog_main_project}>
                <Typography variant="body1" className={classes.merge_projects_dialog_input_header}>
                    <b>Main project</b>
                </Typography>
                <Dropdown
                  values={props.projects.map(x => ({ value: x.id, title: x.name }))}
                  currentValue={mainProject}
                  onChange={onMainProjectChange} />
            </div>
            <div className={classes.merge_projects_select_projects_to_merge}>
                <Typography variant="body1" className={classes.merge_projects_dialog_input_header}>
                    <b>Select projects to merge</b>
                </Typography>
                <DualListBox
                  options={dualListBoxOptions}
                  selected={selected}
                  onChange={onDualListBoxChange}
                  showHeaderLabels
                  className={classes.merge_projects_dual_list_box} />
            </div>
            <div className={classes.merge_projects_dialog_merge_button}>
                <Button type="submit" variant="contained" color="primary" disabled={!selected.length}>
                    Merge
                </Button>
            </div>
        </Paper>
    );

    const renderContent = () => {
        if (processError) {
            return renderErrorNotice();
        }

        if (sentToProcess) {
            return renderProcessing();
        }

        return renderForm();
    };

    function render() {
        return (
            <PopUp title="Merge Projects" onClose={handleCloseDialog} open={props.open}>
                {renderContent()}
            </PopUp>
        );
    }

    return render();
}

export default MergeProjectsDialog;
