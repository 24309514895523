import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    footer: {
        background: 'black',
        color: theme.palette.primary.grey,
        height: '4vh',
        maxWidth: '100vw',
        ...theme.flex.row,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'sticky',
        bottom: '0',
        zIndex: '1300',
    },

});

export default useStyles;
