import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import 'font-awesome/css/font-awesome.min.css';

const useStyles = makeStyles({
    merge_projects_dialog_content_processing: {
        padding: theme.spacing(4, 8)
    },
    merge_projects_dialog_content: {
        background: theme.palette.primary.grey,
        padding: theme.spacing(4, 8),
        '& input': {
            background: 'white'
        },
        '& .MuiInputBase-formControl': {
            background: 'white'
        }
    },
    merge_projects_dialog_main_project: {
        ...theme.flex.column,
        margin: theme.spacing(2, 0),
    },
    merge_projects_select_projects_to_merge: {
        ...theme.flex.column,
        margin: theme.spacing(4, 0)
    },
    merge_projects_dialog_merge_button: {
        ...theme.flex.row,
        justifyContent: 'flex-end',
        '& .MuiButton-root': {
            minWidth: '127px'
        }
    },
    merge_projects_dialog_input_header: {
        margin: theme.spacing(4, 0, 2, 0),
        color: 'black'
    },
    merge_projects_dual_list_box: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '300px',
        fontSize: theme.spacing(3),
        fontWeight: 'bold',
        '& option': {
            fontSize: '1rem',
            paddingTop: '6px',
            paddingBottom: '6px',
            paddingLeft: '6px',
            paddingRight: '6px',
        },
    }
});

export default useStyles;
