import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    en_container: {
        width: '100%',
        ...theme.flex.column,
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: theme.spacing(66),
        },
    },
    en_label: {
    },
});

export default useStyles;
