import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    user_profile: {
        marginLeft: '10px',
    },
    custom_login: {
        '--button-background-color--hover': 'transparent',
    },
    custom_avatar: {
        '--avatar-size': '40px',
    }
});

export default useStyles;
