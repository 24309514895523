import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';

function Footer() {
    const classes = useStyles();
    function render() {
        return (
            <footer className={classes.footer}>
                <Typography variant="body1">
                    Copyright 2001-
                    {new Date().getFullYear()}
                    {' '}
                    Accenture. All rights reserved. Accenture Confidential. For internal use only. Terms of Use | Privacy Statement
                </Typography>
            </footer>
        );
    }
    return render();
}

export default Footer;
