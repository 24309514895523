import { makeStyles } from '@material-ui/styles';
import theme from '../../theme/MainTheme';

const useStyles = makeStyles({
    user_notice_paper: {
        ...theme.flex.column,
        margin: theme.spacing(3),
        padding: theme.spacing(1, 2, 1, 4),
        width: '300px',
        height: '100px',
        zIndex: 9999,
        border: '1px solid black'
    },

    user_notice_paper_success: {
        ...theme.flex.column,
        margin: theme.spacing(3),
        padding: theme.spacing(1, 2, 1, 4),
        width: '300px',
        height: '100px',
        zIndex: 9999,
        border: '1px solid green'
    },

    user_notice_header: {
        ...theme.flex.row,
        marginBottom: theme.spacing(0),
        alignItems: 'center'
    },

    user_notice_check_icon_success: {
        color: 'green',
    },

    user_notice_typography_success: {
        color: 'green',
        marginLeft: theme.spacing(2),
    },

    user_notice_close: {
        color: 'black',
    },

    user_notice_message: {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    filler: {
        flexGrow: 1,
    },
});

export default useStyles;
