import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import InfoLabel from '../../../components/InfoLabel/InfoLabel';
import { closeMainValidationsDeleteConfirmPopUp, deleteMainValidation } from './mainValidationsSlice';
import PopUp from '../../../components/PopUp/PopUp';
import { setSubValidationsParent } from '../SubValidations/subValidationsSlice';
import useStyles from './style';

function DeleteMainValidation() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { deletePopupVisible, mainValidationData, deleteValidationError } = useSelector((state) => state.validations.mainValidations);

    const closePopUp = () => {
        dispatch(closeMainValidationsDeleteConfirmPopUp());
    };

    const deleteValidation = () => {
        dispatch(deleteMainValidation(mainValidationData?.id));
        dispatch(setSubValidationsParent(null));
    };

    return (
        <PopUp title="Delete Main Validation" onClose={closePopUp} open={deletePopupVisible} maxWidth="sm">
            <div className={classes.deleteValidation_typography}>
                <Typography variant="body1">Would you like to delete the following validation?</Typography>
                <InfoLabel title="Category" value={mainValidationData?.category} />
                <InfoLabel title="Code" value={mainValidationData?.code} />
                <InfoLabel title="Text" value={mainValidationData?.text} />
            </div>
            {deleteValidationError && <Alert severity="error">The validation could not be deleted.</Alert>}
            <div className={classes.button_container}>
                <Button variant="contained" color="primary" onClick={deleteValidation}>Delete</Button>
            </div>
        </PopUp>
    );
}

export default DeleteMainValidation;
