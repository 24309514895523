import { Login } from '@microsoft/mgt-react';
import React from 'react';
import useStyles from './style';

function UserProfile() {
    const classes = useStyles();

    function render() {
        return (
            <div className={classes.user_profile}>
                <mgt-login class={classes.custom_login}>
                    <template data-type="signed-in-button-content">
                        <mgt-person class={classes.custom_avatar} person-query="me" view="avatar" />
                    </template>
                </mgt-login>
            </div>

        );
    }

    return render();
}

export default UserProfile;
