import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import App from './app/App';
import store from './app/store';
import * as serviceWorker from './serviceWorker';
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.enableAccountStorageEvents();

if (msalInstance.getActiveAccount()) {
    Providers.globalProvider = new Msal2Provider(msalConfig.auth);
} else {
    msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS) {
            Providers.globalProvider = new Msal2Provider(msalConfig.auth);
        }
    });
}

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <Router>
                <MsalProvider instance={msalInstance}>
                    <App />
                </MsalProvider>
            </Router>
        </Provider>,
        document.getElementById('root')
    );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./app/App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
