/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import MergeProjectsDialog from '../../components/MergeProjectsDialog/MergeProjectsDialog';
import { fetchProjects, setProject } from './projectsSlice';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import PageTitle from '../../components/PageTitle/PageTitle';
import AddNewProjectDialog from '../../components/AddNewProjectDialog/AddNewProjectDialog';
import ErrorNotice from '../../components/ErrorNotice/ErrorNotice';
import { ReactComponent as Arrow } from '../../resources/icons/Arrow.svg';
import { ReactComponent as AddProject } from '../../resources/icons/AddProject.svg';
import UserNotice from '../../components/UserNotice/UserNotice';
import useStyles from './style';

function Projects(props) {
    const { projects, loading, error, setProject, fetchProjects, writeAccess } = props;
    const [openAddNewProjectDialog, setOpenAddNewProjectDialog] = useState(false);
    const [openMergeProjectsDialog, setOpenMergeProjectsDialog] = useState(false);
    const [userNotice, setUserNotice] = useState({ enabled: false, message: '' });
    const classes = useStyles();

    useEffect(() => {
        fetchProjects();
        setProject(null);
    }, []);

    useEffect(() => {
        if (userNotice?.enabled) {
            setTimeout(() => {
                setUserNotice({ enabled: false, message: '' });
            }, 5000);
        }
    }, [userNotice.enabled]);

    function onAddProjectDialogClose() {
        setOpenAddNewProjectDialog(false);
        fetchProjects();
    }

    function onMergeProjectsDialogClose(callFetchProjects = false) {
        setOpenMergeProjectsDialog(false);

        if (callFetchProjects) {
            fetchProjects();
        }
    }

    function renderAddProject() {
        if (writeAccess) {
            return (
                <div className={classes.add_project_box} onClick={handleOpenAddProjectDialog}>
                    <AddProject />
                    <Typography variant="body1" className={classes.add_project_box_label}>
                        <b>
                            Add Project
                        </b>
                    </Typography>
                </div>
            );
        }
        return null;
    }
    function renderProjects() {
        return (
            <div className={classes.projects_container}>
                {renderAddProject()}
                {projects.map((project) => (
                    <Link to={`project/${project.id}`} key={project.id} className={classes.project_container} onClick={() => setProject(project)}>
                        <div>
                            <Tooltip title={project.name} placement="top-start">
                                <Box
                                  component="div"
                                  textOverflow="ellipsis"
                                  overflow="hidden"
                                  className={classes.project_title}
                      >
                                    <Typography variant="subtitle1">
                                        <b>
                                            {project.name}
                                        </b>
                                    </Typography>
                                </Box>
                            </Tooltip>
                            <div
                              className={classes.project_description}
                  >
                                <Typography variant="caption">
                                    {project.description}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.project_arrow}>
                            <Arrow />
                        </div>
                    </Link>
        ))}
            </div>
        );
    }

    function renderAddNewProjectPrompt() {
        if (!writeAccess) {
            return (
                <div className={classes.new_project_prompt}>
                    <Typography variant="body1">
                        Can't add new projects to this workspace
                    </Typography>
                </div>
            );
        }
        return (
            <div className={classes.new_project_prompt}>
                <Typography variant="body1">
                    {prompt}
                    Click "
                </Typography>
                <Button className={classes.new_project_button} variant="text">
                    <Typography className={classes.new_project_button_label} variant="body1" onClick={handleOpenAddProjectDialog}><b>Add New Project</b></Typography>
                </Button>
                <Typography variant="body1">
                    " to create a new project
                </Typography>
            </div>
        );
    }

    function handleOpenAddProjectDialog() {
        setOpenAddNewProjectDialog(true);
    }

    function handleOpenMergeProjectsDialog() {
        setOpenMergeProjectsDialog(true);
    }

    function render() {
        if (loading) {
            return <LoadingIcon />;
        }
        if (error) {
            return <ErrorNotice label={error} />;
        }
        return (
            <>
                <div className={classes.title_container}>
                    <PageTitle label="Projects" />
                    <div className={classes.filler} />
                    {userNotice?.enabled ? <UserNotice enabled={userNotice.enabled} state={userNotice.state} message={userNotice.message} onClose={() => setUserNotice({ enabled: false, message: '' })} />
                    : <Button variant="outlined" color="primary" disabled={!writeAccess || projects?.length < 2} onClick={handleOpenMergeProjectsDialog}>Merge Projects</Button>}
                </div>
                <div className={classes.main_container}>
                    {projects.length !== 0 ? renderProjects() : renderAddNewProjectPrompt()}
                </div>
                <AddNewProjectDialog open={openAddNewProjectDialog} setOpen={setOpenAddNewProjectDialog} onClose={onAddProjectDialogClose} projectNames={projects.map((v) => v.name)} />
                <MergeProjectsDialog open={openMergeProjectsDialog} onClose={onMergeProjectsDialogClose} setUserNotice={setUserNotice} projects={[...projects].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))} />
            </>
        );
    }
    return render();
}

Projects.propTypes = {
    projects: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    fetchProjects: PropTypes.func,
    setProject: PropTypes.func,
};

const mapStateToProps = ({ projects }) => ({
    projects: projects.projects,
    loading: projects.loading,
    writeAccess: projects.writeAccess,
    error: projects.error
});

const mapDispatchToProps = {
    fetchProjects,
    setProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
